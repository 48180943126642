import {
    AllInsightsPageInsights,
    SingleReportPageInsights,
    SingleReportPageIndicator,
    AllInsightsPageIndicator,
    IndicatorFeedPageIndicator,
    SingleReportPageIndicatorGroups,
    AllInsightsPageIndicatorGroups,
    IndicatorFeedPageIndicatorGroups,
    AllInsightsPageIndicatorTags,
    IndicatorFeedPageIndicatorTags,
    SingleReportPageIndicatorTags,
    AllInsightsPageInitiativeTypes,
    IndicatorFeedPageInitiativeTypes,
    SingleReportPageInitiativeTypes,
    OpportunityRead,
    DeliveryStatusEnum,
    FeedbackWrite,
    AggregateFeedbackRead,
    ReactionEnum,
    User,
    SidebarLayoutSingleTeam,
    SidebarLayoutAllTeams,
    SidebarLayoutProfile,
    DashboardLink,
    Indicator,
    IndicatorGroup,
    ShallowTeam,
    OpportunityActionsTakenRead,
    Actions,
    DeepDemographicRead,
    OwnerInOpportunity,
    DeepInsight,
    ShallowContactRead,
    SearchPlanSummaryRead,
    ShallowProfile,
    Profile,
    IntelligenceScope,
    CountryConstraint,
    CountyConstraint,
    IndicatorTagConstraint,
    InitiativeTypeConstraint,
    OwnerConstraint,
    OwnerTypeConstraint,
    PopulationConstraint,
    ProjectBudgetConstraint,
    SourceTimeframeConstraint,
    StateConstraint,
    ConstraintTypeEnum,
    FilterTypeEnum,
    Comment,
    TimingStatuses,
    Subscription,
    SubscriptionContact,
    IndicatorDescription,
    DeepReportRead,
    Tier,
    Product,
    SubscriptionCompany,
    SingleReportPageFilters,
    PaginatedTableInsight,
    ShallowOwnerRead,
    EmailGenerationInputPayload,
    ComparisonResponse,
    Company,
    InsightInOpportunity,
    Notes,
    TimingStatusConstraint,
    ShallowReportRead,
    Source,
    ShallowDrinkingWaterSystemRead,
    ShallowWastewaterFacilityRead,
    DemographicInInsight,
    BaseTeamCustomerTag,
    PaginatedTableTeamActivity,
    SearchPlanFormIntelligenceScopeDetail,
    SearchPlanFormSearchPlanOverviewRead,
    SearchPlanFormIntelligenceScopeListItem,
    SearchPlanFormTeam,
    SearchPlanFormMGDConstraint,
    Country,
    Demographic,
    State,
    Owner,
    OwnerType,
} from "reactQuery/hooks/apiTypes";
import { ExclusionIndicatorGroup } from "reactQuery/hooks/useIndicatorGroups";

export const createMockIndicatorGroup = ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
    exclusions,
    team,
}: ExclusionIndicatorGroup) => ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
    exclusions,
    team,
});

export const createRecordForTeamActivityPage = ({
    id,
    user_creation_date,
    last_login_date,
    name,
    email,
    sales_manager_name,
    sales_regions,
}: Partial<PaginatedTableTeamActivity>): PaginatedTableTeamActivity => ({
    id,
    user_creation_date,
    last_login_date,
    name,
    email,
    sales_manager_name,
    sales_regions,
});

export const createOpportunityOwner = ({
    id,
    whid,
    legal_name,
    state_code,
}: Partial<ShallowOwnerRead>): ShallowOwnerRead => ({
    id,
    whid,
    legal_name,
    state_code,
});

export const createShallowReport = ({
    id,
    date,
    status,
    team,
}: Partial<ShallowReportRead>): ShallowReportRead => ({
    id,
    date,
    status,
    team,
});

export const createInsightInOpportunity = ({
    id,
    summary,
    indicator_groups,
    status,
    drinking_water_systems,
    facilities,
    contacts,
    consultants,
    competitors,
    spd_competitors,
    spd_consultants,
    report,
    sources,
    demographics,
    insight_notes,
    team_customer_tags,
    stage,
    delivery_status,
    display_date,
}: Partial<InsightInOpportunity>): InsightInOpportunity => ({
    id,
    summary,
    indicator_groups,
    status,
    drinking_water_systems,
    facilities,
    contacts,
    consultants,
    competitors,
    spd_competitors,
    spd_consultants,
    report,
    sources,
    demographics,
    insight_notes,
    team_customer_tags,
    stage,
    delivery_status,
    display_date,
});

export const createInsightForPaginatedTable = ({
    id,
    opportunity_owner,
    opportunity_updated,
    summary,
    opportunity_id,
    indicator_groups,
    feedback,
    crm_sync_status,
    display_date,
    priority_insight,
    report_date,
    status,
}: Partial<PaginatedTableInsight>): PaginatedTableInsight => ({
    id,
    opportunity_owner,
    opportunity_updated,
    summary,
    opportunity_id,
    indicator_groups,
    feedback,
    crm_sync_status,
    display_date,
    priority_insight,
    report_date,
    status,
});

export const createInsightForSingleReportPage = ({
    id,
    opportunity_owner,
    opportunity_updated,
    total_population,
    summary,
    opportunity_id,
    indicator_groups,
    team_customer_tags,
    delivery_status,
    feedback,
    crm_sync_status,
}: Partial<SingleReportPageInsights>): SingleReportPageInsights => ({
    id,
    opportunity_owner,
    opportunity_updated,
    total_population,
    summary,
    opportunity_id,
    indicator_groups,
    team_customer_tags,
    delivery_status,
    feedback,
    crm_sync_status,
});

export const createFiltersForSingleReportPage = ({
    indicator_filter,
    opportunity_owner_filter,
    population_filter,
    state_filter,
    status_filter,
}: Partial<SingleReportPageFilters>): SingleReportPageFilters => ({
    indicator_filter,
    opportunity_owner_filter,
    population_filter,
    state_filter,
    status_filter,
});

export const createInsightForAllInsightsPage = ({
    id,
    opportunity_owner,
    opportunity_updated,
    summary,
    opportunity_id,
    indicator_groups,
    report_id,
    report_date,
    team_customer_tags,
    feedback,
    crm_sync_status,
}: Partial<AllInsightsPageInsights>): AllInsightsPageInsights => ({
    id,
    opportunity_owner,
    opportunity_updated,
    summary,
    opportunity_id,
    indicator_groups,
    report_id,
    report_date,
    team_customer_tags,
    feedback,
    crm_sync_status,
});

export const createPageIndicatorGroup = ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
}: Partial<
    | SingleReportPageIndicatorGroups
    | AllInsightsPageIndicatorGroups
    | IndicatorFeedPageIndicatorGroups
>):
    | SingleReportPageIndicatorGroups
    | AllInsightsPageIndicatorGroups
    | IndicatorFeedPageIndicatorGroups => ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
});

export const createPageIndicator = ({
    id,
    value,
    category,
    industry,
    category_id,
    industry_id,
}: Partial<
    SingleReportPageIndicator | AllInsightsPageIndicator | IndicatorFeedPageIndicator
>):
    | SingleReportPageIndicator
    | AllInsightsPageIndicator
    | IndicatorFeedPageIndicator => ({
    id,
    value,
    category,
    industry,
    category_id,
    industry_id,
});

export const createPageIndicatorTag = ({
    value,
}: Partial<
    | SingleReportPageIndicatorTags
    | AllInsightsPageIndicatorTags
    | IndicatorFeedPageIndicatorTags
>):
    | SingleReportPageIndicatorTags
    | AllInsightsPageIndicatorTags
    | IndicatorFeedPageIndicatorTags => ({
    value,
});

export const createPageInitiativeType = ({
    value,
}: Partial<
    | SingleReportPageInitiativeTypes
    | AllInsightsPageInitiativeTypes
    | IndicatorFeedPageInitiativeTypes
>):
    | SingleReportPageInitiativeTypes
    | AllInsightsPageInitiativeTypes
    | IndicatorFeedPageInitiativeTypes => ({
    value,
});

export const createDeliveryStatus = ({
    status,
    value,
}: {
    status: DeliveryStatusEnum;
    value: string;
}) => ({
    [status]: value,
});

export const createFeedback = ({
    opportunity = 0,
    reaction = ReactionEnum.Like,
    feedback = createAggregateFeedback(),
    checkbox_status = false,
    team = 0,
}: Partial<FeedbackWrite>): FeedbackWrite => ({
    opportunity,
    reaction,
    feedback,
    checkbox_status,
    team,
});

export const createAggregateFeedback = ({
    total_likes = 0,
    total_dislikes = 0,
    total_bullseye = 0,
    add_to_crm = false,
    add_to_action_list = false,
    add_to_future_list = false,
    hide = false,
    is_liked = false,
    is_disliked = false,
    is_bullseyed = false,
    is_hidden = false,
}: Partial<AggregateFeedbackRead> = {}): AggregateFeedbackRead => ({
    total_likes,
    total_dislikes,
    total_bullseye,
    add_to_crm,
    add_to_action_list,
    add_to_future_list,
    hide,
    is_liked,
    is_disliked,
    is_bullseyed,
    is_hidden,
});

export const createUser = ({
    id = 1,
    username = "",
    first_name = "",
    last_name = "",
    email = "",
    is_staff = false,
    user_type = null,
    is_datacore = false,
}: Partial<User>): User => ({
    id,
    username,
    first_name,
    last_name,
    email,
    is_staff,
    user_type,
    is_datacore,
});

export const createSidebarLayoutSingleTeam = ({
    id,
    name,
    dashboard_links,
    products,
    tiers,
    status,
}: Partial<SidebarLayoutSingleTeam>): SidebarLayoutSingleTeam => ({
    id,
    name,
    dashboard_links,
    products,
    tiers,
    status,
});

export const createSidebarLayoutAllTeams = ({
    id,
    name,
}: Partial<SidebarLayoutAllTeams>): SidebarLayoutAllTeams => ({
    id,
    name,
});

export const createSidebarLayoutProfile = ({
    user,
    team,
}: Partial<SidebarLayoutProfile>): SidebarLayoutProfile => ({
    user,
    team,
});

export const createDashboardLink = ({
    url,
    product,
}: Partial<DashboardLink>): DashboardLink => ({
    url,
    product,
});

export const createIndicator = ({
    id,
    value,
    category,
    industry,
    category_id,
    industry_id,
    indicator_tags,
}: Partial<Indicator>): Indicator => ({
    id,
    value,
    category,
    industry,
    category_id,
    industry_id,
    indicator_tags,
});

export const createIndicatorFeedItem = ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
    team,
    exclusions,
}: Partial<ExclusionIndicatorGroup>): ExclusionIndicatorGroup => ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
    team,
    exclusions,
});

export const createIndicatorGroup = ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
    team,
}: Partial<IndicatorGroup>): IndicatorGroup => ({
    id,
    indicator,
    indicator_tags,
    initiative_types,
    team,
});

export const createShallowTeam = ({
    id,
    whid,
    name,
    is_deleted,
    number_of_reports,
    dashboard_links,
    status,
}: Partial<ShallowTeam>): ShallowTeam => ({
    id,
    whid,
    name,
    is_deleted,
    number_of_reports,
    dashboard_links,
    status,
});

export const createOpportunity = ({
    id,
    whid,
    assignees,
    insights,
    report_date,
    is_deleted,
    opportunity_value,
    other_assignee,
    feedback,
    contacts,
    name,
    team,
    important,
    sales_stage,
    last_logged_activity,
    owner,
    crm_sync_status,
    opportunity_legacy_insight_type,
    opportunity_rank,
    is_intelligence_feeds,
}: Partial<OpportunityRead>): OpportunityRead => ({
    id,
    whid,
    assignees,
    insights,
    report_date,
    is_deleted,
    opportunity_value,
    other_assignee,
    feedback,
    contacts,
    name,
    team,
    important,
    sales_stage,
    last_logged_activity,
    owner,
    crm_sync_status,
    opportunity_legacy_insight_type,
    opportunity_rank,
    is_intelligence_feeds,
});

export const createLightOwner = ({
    id,
    whid,
    legal_name,
    demographics,
    dw_systems,
    facilities,
    parent,
    last_updated,
    last_verified,
    state_code,
    legacy_owner_whids,
    websites,
}: Partial<Owner>): Owner => ({
    id,
    whid,
    legal_name,
    demographics,
    dw_systems,
    facilities,
    parent,
    last_updated,
    last_verified,
    state_code,
    legacy_owner_whids,
    websites,
});

export const createOwner = ({
    demographics,
    id,
    whid,
    legal_name,
    state_code,
    fiscal,
    dw_systems,
    facilities,
    contacts,
}: Partial<OwnerInOpportunity>): OwnerInOpportunity => ({
    demographics,
    id,
    whid,
    legal_name,
    state_code,
    fiscal,
    dw_systems,
    facilities,
    contacts,
});

export const createLightDemographic = ({
    id,
    name,
    state_code,
}: Partial<Demographic>): Demographic => ({
    id,
    name,
    state_code,
});

export const createDemographic = ({
    id,
    name,
    demographic_type,
    country_code,
    state_code,
    part_of,
    latitude,
    longitude,
    elevation,
    population,
    num_households,
    median_income,
    land_area,
    water_area,
    last_updated,
    address,
    zip,
    county,
}: Partial<DeepDemographicRead>): DeepDemographicRead => ({
    id,
    name,
    demographic_type,
    country_code,
    state_code,
    part_of,
    latitude,
    longitude,
    elevation,
    population,
    num_households,
    median_income,
    land_area,
    water_area,
    last_updated,
    address,
    zip,
    county,
});

export const createOpportunityActionsTaken = ({
    id = 0,
    opportunity = null,
    actions = createAction({}),
    notes,
    author,
    action_date = "2000-10-10",
    last_updated = "",
    contact = null,
}: Partial<OpportunityActionsTakenRead>): OpportunityActionsTakenRead => ({
    id,
    opportunity,
    actions,
    notes,
    author,
    action_date,
    last_updated,
    contact,
});

export const createAction = ({
    id = 0,
    action_type = null,
    action_outcome = null,
    label = "",
}: Partial<Actions>): Actions => ({
    id,
    action_type,
    action_outcome,
    label,
});

export const createTimingStatus = ({
    status = "",
    stage = "",
    id = 0,
}: Partial<TimingStatuses>): TimingStatuses => ({
    status,
    stage,
    id,
});

export const createDeepInsight = ({
    id,
    ai_summary_enabled,
    contacts,
    delivery_status,
    demographics,
    insight_notes,
    indicator_groups,
    opportunity,
    report,
    stage,
    status,
    summary,
    drinking_water_systems,
    facilities,
    consultants,
    competitors,
    report_date,
    team_customer_tags,
    sources,
    feedback,
    target_owners,
    spd_consultants,
    spd_competitors,
}: Partial<DeepInsight>): DeepInsight => ({
    id,
    ai_summary_enabled,
    contacts,
    delivery_status,
    demographics,
    insight_notes,
    indicator_groups,
    opportunity,
    report,
    stage,
    status,
    summary,
    drinking_water_systems,
    facilities,
    consultants,
    competitors,
    report_date,
    team_customer_tags,
    sources,
    feedback,
    target_owners,
    spd_consultants,
    spd_competitors,
});

export const createNote = ({ id, insight, notes, source }: Partial<Notes>): Notes => ({
    id,
    insight,
    notes,
    source,
});

export const createCompany = ({
    id,
    name,
    url,
    segment,
}: Partial<Company>): Company => ({
    id,
    name,
    url,
    segment,
});

export const createShallowContact = ({
    id,
    whid,
    first_name,
    last_name,
    position,
    email,
    email_alternate,
    phone_primary,
    phone_secondary,
    company,
    last_manual_verification_date,
    owner,
}: Partial<ShallowContactRead>): ShallowContactRead => ({
    id,
    whid,
    first_name,
    last_name,
    position,
    email,
    email_alternate,
    phone_primary,
    phone_secondary,
    company,
    last_manual_verification_date,
    owner,
});

export const createShallowProfile = ({
    id,
    user,
}: Partial<ShallowProfile>): ShallowProfile => ({
    id,
    user,
});

export const createState = ({ id, name, code }: Partial<State>): State => ({
    id,
    name,
    code,
});

export const createProfile = ({
    id,
    user,
    team,
    default_excluded_tags,
    permissions,
}: Partial<Profile>): Profile => ({
    id,
    user,
    team,
    default_excluded_tags,
    permissions,
});

export const createCountryConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    countries,
    has_profiles,
}: Partial<CountryConstraint>): CountryConstraint => ({
    constraint_type,
    filter_type,
    countries,
    has_profiles,
});

export const createCountyConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    demographics,
    has_profiles,
}: Partial<CountyConstraint>): CountyConstraint => ({
    constraint_type,
    filter_type,
    demographics,
    has_profiles,
});

export const createIndicatorTagConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    indicator_tags,
    has_profiles,
}: Partial<IndicatorTagConstraint>): IndicatorTagConstraint => ({
    constraint_type,
    filter_type,
    indicator_tags,
    has_profiles,
});

export const createInitiativeTypeConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    initiative_types,
    has_profiles,
}: Partial<InitiativeTypeConstraint>): InitiativeTypeConstraint => ({
    constraint_type,
    filter_type,
    initiative_types,
    has_profiles,
});

export const createMGDConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    maximum,
    minimum,
    has_profiles,
}: Partial<PopulationConstraint>): PopulationConstraint => ({
    constraint_type,
    filter_type,
    maximum,
    minimum,
    has_profiles,
});

export const createSearchPlanFormMGDConstraint = ({
    id,
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    maximum,
    minimum,
    profiles,
    include_null,
}: Partial<SearchPlanFormMGDConstraint>): SearchPlanFormMGDConstraint => ({
    id,
    constraint_type,
    filter_type,
    maximum,
    minimum,
    profiles,
    include_null,
});

export const createOwnerConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    owners,
    has_profiles,
}: Partial<OwnerConstraint>): OwnerConstraint => ({
    constraint_type,
    filter_type,
    owners,
    has_profiles,
});

export const createOwnerTypeConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    owner_types,
    has_profiles,
}: Partial<OwnerTypeConstraint>): OwnerTypeConstraint => ({
    constraint_type,
    filter_type,
    owner_types,
    has_profiles,
});

export const createPopulationConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    maximum,
    minimum,
    has_profiles,
}: Partial<PopulationConstraint>): PopulationConstraint => ({
    constraint_type,
    filter_type,
    maximum,
    minimum,
    has_profiles,
});

export const createProjectBudgetConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    maximum,
    minimum,
    has_profiles,
}: Partial<ProjectBudgetConstraint>): ProjectBudgetConstraint => ({
    constraint_type,
    filter_type,
    maximum,
    minimum,
    has_profiles,
});

export const createSourceTimeframeConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    maximum,
    has_profiles,
}: Partial<SourceTimeframeConstraint>): SourceTimeframeConstraint => ({
    constraint_type,
    filter_type,
    maximum,
    has_profiles,
});

export const createStateConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    states,
    has_profiles,
}: Partial<StateConstraint>): StateConstraint => ({
    constraint_type,
    filter_type,
    states,
    has_profiles,
});

export const createTimingStatusConstraint = ({
    constraint_type = ConstraintTypeEnum.Include,
    filter_type = FilterTypeEnum.PriorityHigh,
    statuses,
    has_profiles,
}: Partial<TimingStatusConstraint>): TimingStatusConstraint => ({
    constraint_type,
    filter_type,
    statuses,
    has_profiles,
});

export const createTier = ({ name }: Partial<Tier>): Tier => ({
    name,
});

export const createIntelligenceScope = ({
    added_by,
    additional_notes,
    country_constraints,
    county_constraints,
    date_created,
    id: number,
    indicator,
    indicatortag_constraints,
    industry,
    initiativetype_constraints,
    last_updated,
    mgd_constraints,
    rank,
    owner_constraints,
    owner_type_constraints,
    population_constraints,
    project_budget_constraints,
    source_timeframe_constraints,
    state_constraints,
    timing_status_constraints,
    updated_by,
}: Partial<IntelligenceScope>): IntelligenceScope => ({
    added_by,
    additional_notes,
    country_constraints,
    county_constraints,
    date_created,
    id: number,
    indicator,
    indicatortag_constraints,
    industry,
    initiativetype_constraints,
    last_updated,
    mgd_constraints,
    rank,
    owner_constraints,
    owner_type_constraints,
    population_constraints,
    project_budget_constraints,
    source_timeframe_constraints,
    state_constraints,
    timing_status_constraints,
    updated_by,
});

export const createComment = ({
    added_by,
    date_created,
    last_updated,
    text,
    updated_by,
}: Partial<Comment>): Comment => ({
    added_by,
    date_created,
    last_updated,
    text,
    updated_by,
});

export const createIndicatorDescription = ({
    id,
    value,
    category,
    industry,
    category_id,
    industry_id,
    indicator_tags,
    description,
}: Partial<IndicatorDescription>): IndicatorDescription => ({
    id,
    value,
    category,
    industry,
    category_id,
    industry_id,
    indicator_tags,
    description,
});

export const createSubscriptionContact = ({
    contact_department,
    contact_level,
    rank,
}: Partial<SubscriptionContact>): SubscriptionContact => ({
    contact_department,
    contact_level,
    rank,
});

export const createSubscriptionCompany = ({
    name,
    segment,
    url,
}: Partial<SubscriptionCompany>): SubscriptionCompany => ({
    name,
    segment,
    url,
});

export const createSubscription = ({
    added_by,
    basic_facility,
    bid_check,
    competitors,
    consultants,
    contacts,
    date_created,
    last_updated,
    owner_office,
    permit_violation,
    project_budget,
    updated_by,
    priority_owners,
}: Partial<Subscription>): Subscription => ({
    added_by,
    basic_facility,
    bid_check,
    competitors,
    consultants,
    contacts,
    date_created,
    last_updated,
    owner_office,
    permit_violation,
    project_budget,
    updated_by,
    priority_owners,
});

export const createSearchPlanSummary = ({
    account_manager,
    added_by,
    comments,
    date_created,
    delivery_cadence,
    intelligence_scopes,
    last_updated,
    maximum_insights,
    minimum_insights,
    plan_type,
    product,
    subscription,
    team,
    updated_by,
    uuid,
}: Partial<SearchPlanSummaryRead>): SearchPlanSummaryRead => ({
    account_manager,
    added_by,
    comments,
    date_created,
    delivery_cadence,
    intelligence_scopes,
    last_updated,
    maximum_insights,
    minimum_insights,
    plan_type,
    product,
    subscription,
    team,
    updated_by,
    uuid,
});

export const createDeepReportRead = ({
    id,
    date,
    status,
    notes,
    team,
    insights,
    is_deleted,
    next_report_id,
    prev_report_id,
}: Partial<DeepReportRead>): DeepReportRead => ({
    id,
    date,
    status,
    notes,
    team,
    insights: insights || [],
    is_deleted,
    next_report_id,
    prev_report_id,
});

export const createProduct = ({
    id,
    name,
    product_type,
    path,
}: Partial<Product>): Product => ({
    id,
    name,
    product_type,
    path,
});

export const createEmailGenerationInput = ({
    user,
    contact,
    case_study_description,
    products_services_and_expertise,
    primary_goal,
    call_to_action,
    tone,
    saved,
}: Partial<EmailGenerationInputPayload>): EmailGenerationInputPayload => ({
    user,
    contact,
    case_study_description,
    products_services_and_expertise,
    primary_goal,
    call_to_action,
    tone,
    saved,
});

export const createDocumentCompare = ({
    identifier,
    signed_viewer_url,
    expiry_time,
}: Partial<ComparisonResponse>): ComparisonResponse => ({
    identifier,
    signed_viewer_url,
    expiry_time,
});

export const createSource = ({
    id,
    title,
    url,
    date,
    extract,
    insight,
    page_number,
}: Partial<Source>): Source => ({
    id,
    title,
    url,
    date,
    extract,
    insight,
    page_number,
});

export const createShallowDrinkingWaterSystemRead = ({
    id,
    name,
    public_water_system_id,
    system_type,
    population_served,
    state_code,
    last_updated,
    owner_type,
    active,
}: Partial<ShallowDrinkingWaterSystemRead>): ShallowDrinkingWaterSystemRead => ({
    id,
    name,
    public_water_system_id,
    system_type,
    population_served,
    state_code,
    last_updated,
    owner_type,
    active,
});

export const createShallowWastewaterFacilityRead = ({
    id,
    facility_name,
    design_flow,
    npdes_id,
}: Partial<ShallowWastewaterFacilityRead>): ShallowWastewaterFacilityRead => ({
    id,
    facility_name,
    design_flow,
    npdes_id,
});

export const createDemographicInInsight = ({
    id,
    name,
    demographic_type,
    country_code,
    state_code,
    population,
}: Partial<DemographicInInsight>): DemographicInInsight => ({
    id,
    name,
    demographic_type,
    country_code,
    state_code,
    population,
});

export const createBaseTeamCustomerTag = ({
    id,
    legacy,
    name,
}: Partial<BaseTeamCustomerTag>): BaseTeamCustomerTag => ({
    id,
    legacy,
    name,
});

export const createSearchPlanOverview = ({
    account_manager,
    id,
    intelligence_scopes,
    product,
    status,
    team,
}: Partial<SearchPlanFormSearchPlanOverviewRead>): SearchPlanFormSearchPlanOverviewRead => ({
    account_manager,
    id,
    intelligence_scopes,
    product,
    status,
    team,
});

export const createSearchPlanTeam = ({
    id,
    name,
}: Partial<SearchPlanFormTeam>): SearchPlanFormTeam => ({
    id,
    name,
});

export const createCountry = ({ id, name, code }: Partial<Country>): Country => ({
    id,
    name,
    code,
});

export const createSearchPlanIntelligenceScopeListItem = ({
    id,
    indicator,
    industry,
    rank,
}: Partial<SearchPlanFormIntelligenceScopeListItem>): SearchPlanFormIntelligenceScopeListItem => ({
    id,
    indicator,
    industry,
    rank,
});

export const createIntelligenceScopeDetail = ({
    id,
    industry,
    indicator,
    rank,
    additional_notes,
    country_constraints,
    state_constraints,
    county_constraints,
    owner_constraints,
    owner_type_constraints,
    population_constraints,
    initiativetype_constraints,
    timing_status_constraints,
    indicatortag_constraints,
    project_budget_constraints,
    source_timeframe_constraints,
    mgd_constraints,
    display_start_date_constraints,
    all_constraints_to_copy,
}: Partial<SearchPlanFormIntelligenceScopeDetail>): SearchPlanFormIntelligenceScopeDetail => ({
    id,
    industry,
    indicator,
    rank,
    additional_notes,
    country_constraints,
    state_constraints,
    county_constraints,
    owner_constraints,
    owner_type_constraints,
    population_constraints,
    initiativetype_constraints,
    timing_status_constraints,
    indicatortag_constraints,
    project_budget_constraints,
    source_timeframe_constraints,
    mgd_constraints,
    display_start_date_constraints,
    all_constraints_to_copy,
});

export const createOwnerType = ({ id, owner_type }: Partial<OwnerType>): OwnerType => ({
    id,
    owner_type,
});
