import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { warningLabelTypeKeys } from "reactQuery/keys";

export const useGetWarningLabelTypes = ({
    enabled = true,
}: {
    enabled?: boolean;
}): Partial<UseQueryResult<any>> => {
    const queryKey = warningLabelTypeKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/warning_label_types/`
            );
            return data;
        },
        enabled,
    });
};
