import { Modal, Button, Typography, Row, List, Space, Select } from "antd";
import { useState } from "react";

import { useUpdateIPLResults } from "reactQuery/hooks/useIPLResults";
import { useGetStaffUsers } from "reactQuery/hooks/useUsers";
import { useSnackStore } from "stores/zustandStore";

const { Text } = Typography;

export const AssignIPLResultsModal = ({
    handleClose,
    selectedRows = [],
    setSelectedRows,
    visible,
    feedType,
    defaultPageSize,
}) => {
    const [confirmedDropdownSelection, setConfirmedDropdownSelection] = useState(false);
    const [selectedAssignee, setSelectedAssignee] = useState(null);

    const iplResults = selectedRows;
    const iplResultIds = [...new Set(iplResults.map((iplResult) => iplResult.id))];

    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);
    const staffUsersQuery = useGetStaffUsers({});

    const update_ipl_results_assignee_query = useUpdateIPLResults({
        feedType,
        defaultPageSize,
    });

    const resetAndCloseModal = (resetSelectedRows) => {
        setConfirmedDropdownSelection(false);
        setSelectedAssignee(null);
        setSelectedRows(resetSelectedRows ? [] : selectedRows);
        handleClose();
    };

    const onSuccess = (response) => {
        if (response.failed_ipl_result_ids?.length) {
            displayErrorSnack({
                message: `Some IPL Results assigned. There was a problem assigning the following IPL Result${
                    response.failed_ipl_result_ids === 1 ? "" : "s"
                }: [${response.failed_ipl_result_ids.join(", ")}]`,
            });
        } else {
            displaySuccessSnack({
                message: "Assigned successfully",
            });
        }
        resetAndCloseModal(true);
    };
    const onError = () => {
        displayErrorSnack({
            message: `There was a problem assigning the selected IPL Result${
                iplResults.length === 1 ? "" : "s"
            }`,
        });
    };

    const handleSubmit = () => {
        update_ipl_results_assignee_query.mutate(
            {
                field: "assignee",
                assigneeObj: selectedAssignee,
                ids: iplResultIds,
            },
            { onSuccess, onError }
        );
    };

    const confirmationPage = !!selectedAssignee && !!confirmedDropdownSelection;

    return (
        <Modal
            title={`Assign Selected IPL Result${iplResults.length === 1 ? "" : "s"}`}
            open={visible}
            onCancel={() => resetAndCloseModal(false)}
            forceRender
            bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
            footer={
                <>
                    <Row justify="space-between">
                        <Button
                            key="cancel"
                            type="default"
                            onClick={() => resetAndCloseModal(false)}
                        >
                            Cancel
                        </Button>
                        {confirmationPage ? (
                            <Button
                                key="submit"
                                type="primary"
                                onClick={() => handleSubmit()}
                            >
                                Assign IPL Results
                            </Button>
                        ) : selectedAssignee ? (
                            <Button
                                key="submit"
                                type="primary"
                                onClick={() => setConfirmedDropdownSelection(true)}
                                loading={update_ipl_results_assignee_query.isLoading}
                            >
                                Select Assignee
                            </Button>
                        ) : null}
                    </Row>
                </>
            }
        >
            {confirmationPage ? (
                <Space direction="vertical" size="large" style={{ display: "flex" }}>
                    <Text>{`You are about to assign the following ${
                        iplResults.length
                    } IPL Result${iplResults.length === 1 ? "" : "s"} to ${
                        selectedAssignee.title
                    }`}</Text>

                    <List
                        bordered
                        dataSource={iplResults}
                        size="small"
                        renderItem={(iplResult: any) => {
                            return (
                                <List.Item>
                                    <Text>{`[${iplResult.id}] - ${iplResult.owner} - ${iplResult.indicator?.[0]}`}</Text>
                                </List.Item>
                            );
                        }}
                    />
                </Space>
            ) : (
                <Select
                    onChange={(_, option) => setSelectedAssignee(option)}
                    placeholder="Filter by assignee..."
                    showSearch
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        const fullName = option.title.toLowerCase();
                        return fullName.includes(input.toLowerCase());
                    }}
                >
                    {staffUsersQuery.isLoading ? (
                        <Select.Option value="disabled" disabled>
                            Loading...
                        </Select.Option>
                    ) : staffUsersQuery.isSuccess ? (
                        staffUsersQuery.data.map((user) => (
                            <Select.Option
                                key={user.id}
                                value={user.id}
                                title={`${user.first_name} ${user.last_name}`}
                            >
                                {`${user.first_name} ${user.last_name}`}
                            </Select.Option>
                        ))
                    ) : (
                        <Select.Option value="disabled" disabled>
                            There was a problem loading assignees
                        </Select.Option>
                    )}
                </Select>
            )}
        </Modal>
    );
};
