import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import {
    iplResultStatusChoiceKeys,
    iplResultAssigneeActionedStatusChoiceKeys,
} from "reactQuery/keys";

export interface StatusChoiceType {
    [key: string]: string;
}

export const useGetIPLResultAssigneeActionedStatusChoices = ({
    enabled = true,
}: {
    enabled?: boolean;
}): Partial<UseQueryResult<StatusChoiceType>> => {
    const queryKey = iplResultAssigneeActionedStatusChoiceKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<StatusChoiceType> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/ipl_result_assignee_actioned_status_choices/`
            );
            return data;
        },
        enabled,
    });
};

export const useGetIPLResultStatusChoices = ({
    enabled = true,
}: {
    enabled?: boolean;
}): Partial<UseQueryResult<StatusChoiceType>> => {
    const queryKey = iplResultStatusChoiceKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<StatusChoiceType> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/ipl_result_status_choices/`
            );
            return data;
        },
        enabled,
    });
};
