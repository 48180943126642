import { Form, Select } from "antd";

import { InitiativeType } from "reactQuery/hooks/apiTypes";
import { useGetAllInitiativeTypes } from "reactQuery/hooks/useInitiativeTypes";

import { ConstraintRecordProps } from "../columnInfo";
import { validateMultiSelectWithIncludeNull } from "../validationHelper";

const InitiativeTypesSelect = ({
    constraintName,
    constraintId,
}: ConstraintRecordProps) => {
    const initiativeTypesQuery = useGetAllInitiativeTypes({});
    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[constraintName, `constraint_id_${constraintId}`, "initiative_types"]}
            preserve={false}
            rules={[
                ({ getFieldValue }) =>
                    validateMultiSelectWithIncludeNull(
                        constraintName,
                        constraintId,
                        getFieldValue,
                        "Please select one or more initiative types"
                    ),
            ]}
        >
            <Select
                placeholder="Select one or more initiative types"
                showSearch
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const initiativeType = option.title.toLowerCase();
                    return initiativeType.includes(input.toLowerCase());
                }}
            >
                {initiativeTypesQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : initiativeTypesQuery.isSuccess ? (
                    initiativeTypesQuery.data.map((initiativeType: InitiativeType) => {
                        return (
                            <Select.Option
                                key={initiativeType.id}
                                value={initiativeType.id}
                                title={initiativeType.value}
                            >
                                {initiativeType.value}
                            </Select.Option>
                        );
                    })
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading initiative type options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default InitiativeTypesSelect;
