import { ThunderboltTwoTone } from "@ant-design/icons";
import { Checkbox, DatePicker, Divider, Form, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";

import AutocompleteFormSelect from "components/molecules/AutocompleteFormSelect";
import { FEED_TYPES } from "constants/feedTypes";
import { useGetCountyDemographicsByQuery } from "reactQuery/hooks/useCounties";
import { useGetCountries } from "reactQuery/hooks/useCountries";
import { useGetIndicatorIndustries } from "reactQuery/hooks/useIndicatorIndustries";
import { useGetNonLegacyIndicators } from "reactQuery/hooks/useIndicators";
import { useGetIndicatorTagsByQuery } from "reactQuery/hooks/useIndicatorTags";
import { useGetAllInitiativeTypes } from "reactQuery/hooks/useInitiativeTypes";
import { useGetInsightStatuses } from "reactQuery/hooks/useInsights";
import { useGetOwnersByQuery } from "reactQuery/hooks/useOwners";
import { useGetOwnerTypes } from "reactQuery/hooks/useOwnerTypes";
import { useSearchQueries, useGetQueryTypes } from "reactQuery/hooks/useQueries";
import { useGetStates } from "reactQuery/hooks/useStates";
import {
    useGetIPLResultStatusChoices,
    useGetIPLResultAssigneeActionedStatusChoices,
} from "reactQuery/hooks/useTableAssociatedData";
import { useGetStaffUsers } from "reactQuery/hooks/useUsers";
import { useGetWarningLabelTypes } from "reactQuery/hooks/useWarningLabels";

export const IPLResultFiltersTemplate = ({
    handleClose,
    feedType,
    filters,
    form,
    setFilters,
}: {
    handleClose: () => void;
    feedType: string;
    filters: any;
    form: any;
    setFilters: (filters: any) => void;
}) => {
    useEffect(() => {
        if (filters) {
            form.resetFields();
            form.setFieldsValue(filters);
        }
    }, [filters]);

    const { RangePicker } = DatePicker;

    const [currentDocumentCreationDate, setCurrentDocumentCreationDate] =
        useState<boolean>(null);

    const handleValuesChanged = (_, allValues) => {
        if (
            allValues.document_creation_date &&
            (!currentDocumentCreationDate || currentDocumentCreationDate === null)
        ) {
            setCurrentDocumentCreationDate(true);
            if (form.getFieldValue("document_creation_date") !== null) {
                form.setFieldValue("exclude_document_creation_date_null", null);
            }
        } else if (
            !allValues.document_creation_date &&
            (currentDocumentCreationDate || currentDocumentCreationDate === null)
        ) {
            setCurrentDocumentCreationDate(false);
            if (form.getFieldValue("document_creation_date") === null) {
                form.setFieldValue("include_document_creation_date_null", null);
            }
        }
    };

    const onFinish = () => {
        // Ensure flat values when labelInValue is used
        Object.keys(form.getFieldsValue(true)).map((field) => {
            const fieldValue = form.getFieldValue(field);
            if (fieldValue) {
                if (Array.isArray(fieldValue)) {
                    const updatedFieldValues = fieldValue.map((nestedValue) => {
                        if (nestedValue?.value) {
                            return nestedValue.value;
                        } else {
                            return nestedValue;
                        }
                    });
                    form.setFieldsValue({ [field]: updatedFieldValues });
                } else if (fieldValue.value) {
                    form.setFieldsValue({ [field]: fieldValue });
                }
            }
        });
        setFilters({
            ...form.getFieldsValue(true),
            exclude_document_creation_date_null:
                form.getFieldValue("exclude_document_creation_date_null") === false
                    ? null
                    : form.getFieldValue("exclude_document_creation_date_null"),
            include_document_creation_date_null:
                form.getFieldValue("include_document_creation_date_null") === false
                    ? null
                    : form.getFieldValue("include_document_creation_date_null"),
        });
        handleClose();
    };

    const initialValues = {
        ...filters,
        min_population: filters.population?.[0]?.min,
        max_population: filters.population?.[0]?.max,
    };

    const documentCreationDateSpecified =
        currentDocumentCreationDate === true || currentDocumentCreationDate === false
            ? currentDocumentCreationDate
            : !!form.getFieldValue("document_creation_date");

    const resultOptions = [
        { value: "All", label: "All" },
        { value: "True", label: "Yes" },
        { value: "False", label: "No" },
    ];

    const newFilterHighlightColor = "#80B000";
    const newFilterHighlight = (
        <div style={{ fontSize: 12, color: newFilterHighlightColor, marginBottom: -5 }}>
            <ThunderboltTwoTone twoToneColor={newFilterHighlightColor} />
            &nbsp;<span>New</span>
        </div>
    );

    return (
        <>
            <Form
                initialValues={initialValues}
                id="table-filters-form"
                form={form}
                name="table-filters-form"
                labelWrap={true}
                layout="vertical"
                onFinish={onFinish}
                style={{ width: "100%" }}
                preserve={true}
                onValuesChange={handleValuesChanged}
            >
                <Divider plain style={{ fontWeight: "700" }}>
                    Result Filters
                </Divider>
                <AutocompleteFormSelect
                    allowArbitrary={true}
                    allowMultiple={true}
                    highlight={newFilterHighlight}
                    id="keywords"
                    label="Keywords"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="industry_ids"
                    label="Industry"
                    placeholderText="Filter by industry..."
                    searchQuery={useGetIndicatorIndustries}
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filterOption={(input, option) => {
                        const indicator = option.title.toLowerCase();
                        return indicator.includes(input.toLowerCase());
                    }}
                    filters={filters}
                    form={form}
                    id="indicator_ids"
                    label="Indicator"
                    placeholderText="Filter by indicator..."
                    searchQuery={useGetNonLegacyIndicators}
                    textTemplate="${value}[[ - ${category}]][[ - ${industry}]]"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="indicator_tag_ids"
                    label="Indicator Tag"
                    placeholderText="Filter by indicator tag..."
                    searchAsUserTypes={true}
                    searchQuery={useGetIndicatorTagsByQuery}
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="query_ids"
                    label="Query"
                    placeholderText="Filter by query..."
                    searchAsUserTypes={true}
                    searchQuery={useSearchQueries}
                    textTemplate="${query_text}[[, ${indicator}]]"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="query_types"
                    label="Query Types"
                    placeholderText=""
                    searchQuery={useGetQueryTypes}
                />
                <Form.Item name="metadata_score" label="Minimum Metadata Score">
                    <InputNumber min={-100} max={100} />
                </Form.Item>
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="initiative_types"
                    label="Initiative Types"
                    placeholderText=""
                    searchQuery={useGetAllInitiativeTypes}
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    disabled={[
                        FEED_TYPES.LW_MY_ACTIONED,
                        FEED_TYPES.LW_MY_QUEUE,
                    ].includes(feedType)}
                    filterOption={(input, option) => {
                        const fullName = option.title.toLowerCase();
                        return fullName.includes(input.toLowerCase());
                    }}
                    filters={filters}
                    form={form}
                    id={
                        feedType === FEED_TYPES.LW_ALL_INSIGHTS
                            ? "creator_ids"
                            : "assignee_ids"
                    }
                    label={
                        feedType === FEED_TYPES.LW_ALL_INSIGHTS ? "Creator" : "Assignee"
                    }
                    placeholderText={`Filter by ${
                        feedType === FEED_TYPES.LW_ALL_INSIGHTS
                            ? "creators"
                            : "assignees"
                    }...`}
                    searchQuery={useGetStaffUsers}
                    textTemplate="${first_name} ${last_name}"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    disabled={feedType === FEED_TYPES.LW_MY_QUEUE}
                    filters={filters}
                    form={form}
                    id="statuses"
                    label="IPL Status"
                    placeholderText=""
                    searchQuery={useGetIPLResultStatusChoices}
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="review_statuses"
                    label="Review Status"
                    placeholderText=""
                    searchQuery={useGetIPLResultAssigneeActionedStatusChoices}
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="insight_statuses"
                    label="Insight Status"
                    placeholderText=""
                    searchQuery={useGetInsightStatuses}
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id={
                        feedType === FEED_TYPES.LW_ALL_INSIGHTS
                            ? "linked_ipl_result"
                            : "linked_insight"
                    }
                    label={
                        feedType === FEED_TYPES.LW_ALL_INSIGHTS
                            ? "Linked IPL Result"
                            : "Linked Insight"
                    }
                    options={resultOptions}
                    placeholderText="All"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    highlight={newFilterHighlight}
                    id="warning_label_types"
                    label="Warning Label Types"
                    placeholderText=""
                    searchQuery={useGetWarningLabelTypes}
                />
                <AutocompleteFormSelect
                    allowArbitrary={true}
                    allowMultiple={true}
                    id="ipl_result_ids"
                    label="IPL Result IDs"
                />
                <Divider plain style={{ fontWeight: "700" }}>
                    Owner Filters
                </Divider>
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="owner_ids"
                    label="Owner"
                    placeholderText="Filter by owner..."
                    searchAsUserTypes={true}
                    searchQuery={useGetOwnersByQuery}
                    textTemplate="${legal_name}[[, ${state_code}]]"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="countries"
                    label="Country"
                    placeholderText="Filter by country..."
                    searchQuery={useGetCountries}
                    textKey="code"
                    valueKey="code"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="states"
                    label="State"
                    placeholderText="Filter by state..."
                    searchQuery={useGetStates}
                    textKey="code"
                    valueKey="code"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    filters={filters}
                    form={form}
                    id="county_ids"
                    label="County"
                    placeholderText="Filter by county..."
                    searchAsUserTypes={true}
                    searchQuery={useGetCountyDemographicsByQuery}
                    textTemplate="${name}, ${state_code}"
                />
                <AutocompleteFormSelect
                    allowMultiple={true}
                    form={form}
                    id="owner_types"
                    label="Owner Type"
                    placeholderText="Filter by owner type..."
                    searchQuery={useGetOwnerTypes}
                    textKey="owner_type"
                    valueKey="owner_type"
                />
                <Form.Item label="Population">
                    <Input.Group compact>
                        <Form.Item name="min_population" noStyle>
                            <Input
                                style={{
                                    width: 100,
                                    textAlign: "center",
                                    borderRight: 0,
                                }}
                                placeholder="Minimum"
                            />
                        </Form.Item>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: "none",
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Form.Item name="max_population" noStyle>
                            <Input
                                className="site-input-right"
                                style={{
                                    width: 100,
                                    textAlign: "center",
                                    borderLeft: 0,
                                }}
                                placeholder="Maximum"
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Divider plain style={{ fontWeight: "700" }}>
                    Date Filters
                </Divider>
                <Form.Item name="document_creation_date" label="Document Creation Date">
                    <RangePicker />
                </Form.Item>
                {documentCreationDateSpecified ? (
                    <Form.Item
                        name="include_document_creation_date_null"
                        label="Document Creation Date Null"
                        valuePropName="checked"
                        wrapperCol={{ offset: 1, span: 16 }}
                        extra="Selecting this checkbox will include results with no document creation date in addition to those within the document creation date range selected above."
                    >
                        <Checkbox>Include</Checkbox>
                    </Form.Item>
                ) : null}
                {!documentCreationDateSpecified ? (
                    <Form.Item
                        name="exclude_document_creation_date_null"
                        label="Document Creation Date Null"
                        valuePropName="checked"
                        wrapperCol={{ offset: 1, span: 16 }}
                        extra="Selecting this checkbox will exclude results with no document creation date, which are included by default."
                    >
                        <Checkbox>Exclude</Checkbox>
                    </Form.Item>
                ) : null}
                <Form.Item name="date_created" label="IPL Result Creation Date">
                    <RangePicker />
                </Form.Item>
                <Form.Item name="date_assigned" label="IPL Assigned Date">
                    <RangePicker />
                </Form.Item>
                {feedType !== FEED_TYPES.LW_ALL_INSIGHTS ? (
                    <Form.Item name="date_actioned" label="Date Actioned">
                        <RangePicker />
                    </Form.Item>
                ) : null}
                <Form.Item name="insight_creation_date" label="Insight Creation Date">
                    <RangePicker />
                </Form.Item>
                <Form.Item name="date_updated" label="Last Updated Date">
                    <RangePicker />
                </Form.Item>
            </Form>
        </>
    );
};
