import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { CountryKeys } from "reactQuery/keys";

import { Country } from "./apiTypes";

export const useGetCountries = ({
    enabled = true,
}: {
    enabled?: boolean;
}): UseQueryResult<Country[]> => {
    const queryKey = CountryKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Country[]> =>
            (await nautilusGetRequest("/api/universe/countries/")).data,
        enabled,
    });
};
