import { Form, Select } from "antd";

import { State } from "reactQuery/hooks/apiTypes";
import { useGetStates } from "reactQuery/hooks/useStates";

import { ConstraintRecordProps } from "../columnInfo";

const StatesSelect = ({ constraintName, constraintId }: ConstraintRecordProps) => {
    const statesQuery = useGetStates({});
    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[constraintName, `constraint_id_${constraintId}`, "states"]}
            preserve={false}
            rules={[
                {
                    required: true,
                    message: "Please select one or more states / provinces",
                },
            ]}
        >
            <Select
                placeholder="Select one or more states / provinces"
                showSearch
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const state = option.title.toLowerCase();
                    return state.includes(input.toLowerCase());
                }}
            >
                {statesQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : statesQuery.isSuccess ? (
                    statesQuery.data.map((state: State) => {
                        const stateName = `${state.name} (${state.code})`;
                        return (
                            <Select.Option
                                key={state.id}
                                value={state.id}
                                title={stateName}
                            >
                                {stateName}
                            </Select.Option>
                        );
                    })
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading state options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default StatesSelect;
