import { generatePath } from "react-router";

import { queryParamBuilder } from "utils/useManagedQueryParams";

import { FILTER_TYPES } from "./options";
import { filterTypesType } from "./types";

interface pathBuilderProps {
    excludeQueryParams?: string[];
    id: string | number;
    includeAllQueryParams?: boolean;
    path: string;
    queryParam: string;
    setQueryParam?: boolean;
}

interface allowFiltersQueryLoadTypes {
    loadFilters: filterTypesType;
    isStaff: boolean;
    filtersEnabled: boolean;
}

export const pathBuilder = ({
    excludeQueryParams,
    id,
    includeAllQueryParams = false,
    path,
    queryParam,
    setQueryParam = false,
}: pathBuilderProps) => {
    const queryParams = queryParamBuilder({
        excludeParams: excludeQueryParams || [queryParam],
        includeBaseParams: true,
        includeExistingParams: includeAllQueryParams,
        startChar: setQueryParam ? "&" : "?",
    });

    const fullPath = setQueryParam
        ? `${path}?${queryParam}=${id}${queryParams}`
        : `${generatePath(path, {
              [queryParam]: id,
          })}${queryParams}`;

    return fullPath;
};

export const allowFiltersQueryLoad = ({
    loadFilters,
    isStaff,
    filtersEnabled,
}: allowFiltersQueryLoadTypes) => {
    switch (loadFilters) {
        case FILTER_TYPES.DEFAULT:
            return true;
        case FILTER_TYPES.DISABLED:
            return false;
        case FILTER_TYPES.EXPLICIT_LOAD:
            return filtersEnabled;
        case FILTER_TYPES.MODAL:
            return false;
        case FILTER_TYPES.STAFF_EXPLICIT_LOAD:
            if (isStaff) {
                return filtersEnabled;
            } else {
                return true;
            }
        default:
            return false;
    }
};

export const getDateXDaysAgo = (daysAgo: number) => {
    const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - daysAgo));
    return thirtyDaysAgo.toISOString().split("T")[0];
};
