import { Button, Modal, Form } from "antd";

import { IPLResultFiltersTemplate } from "../filterTemplates/IPLResultFiltersTemplate";
import { FILTER_TEMPLATES } from "../options";

interface FiltersModalProps {
    clearFilters: () => void;
    handleClose: () => void;
    feedType: string;
    filtersTemplate: string;
    filters: any;
    visible: boolean;
    setFilters: (filters: any) => void;
}

const FiltersModal = ({
    clearFilters,
    handleClose,
    feedType,
    filtersTemplate,
    filters,
    visible,
    setFilters,
}: FiltersModalProps) => {
    const [form] = Form.useForm();
    const onModalCancel = () => {
        handleClose();
    };

    return (
        <Modal
            title="Filters"
            open={visible}
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            width={1000}
            footer={
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        type="primary"
                        danger
                        onClick={() => {
                            clearFilters();
                            form.resetFields();
                            handleClose();
                        }}
                    >
                        Clear Filters
                    </Button>
                    <div>
                        <Button key="cancel" type="default" onClick={onModalCancel}>
                            Close
                        </Button>
                        <Button
                            form={"table-filters-form"}
                            key="submit"
                            htmlType="submit"
                            type="primary"
                        >
                            Apply Filters
                        </Button>
                    </div>
                </div>
            }
        >
            {filtersTemplate === FILTER_TEMPLATES.IPL_RESULTS ? (
                <IPLResultFiltersTemplate
                    handleClose={handleClose}
                    feedType={feedType}
                    filters={filters}
                    form={form}
                    setFilters={setFilters}
                ></IPLResultFiltersTemplate>
            ) : null}
        </Modal>
    );
};

export default FiltersModal;
