import { Form, Select } from "antd";

import { OwnerType } from "reactQuery/hooks/apiTypes";
import { useGetOwnerTypes } from "reactQuery/hooks/useOwnerTypes";

import { ConstraintRecordProps } from "../columnInfo";
import { validateMultiSelectWithIncludeNull } from "../validationHelper";

const OwnerTypesSelect = ({ constraintName, constraintId }: ConstraintRecordProps) => {
    const ownerTypesQuery = useGetOwnerTypes({ enabled: true });
    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[constraintName, `constraint_id_${constraintId}`, "owner_types"]}
            preserve={false}
            rules={[
                ({ getFieldValue }) =>
                    validateMultiSelectWithIncludeNull(
                        constraintName,
                        constraintId,
                        getFieldValue,
                        "Please select one or more owner types"
                    ),
            ]}
        >
            <Select
                placeholder="Select one or more owner types"
                showSearch
                data-testid="owner-types-select"
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const ownerType = option.title.toLowerCase();
                    return ownerType.includes(input.toLowerCase());
                }}
            >
                {ownerTypesQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : ownerTypesQuery.isSuccess ? (
                    ownerTypesQuery.data.map((ownerType: OwnerType) => {
                        return (
                            <Select.Option
                                key={ownerType.id}
                                value={ownerType.id}
                                title={ownerType.owner_type}
                            >
                                {ownerType.owner_type}
                            </Select.Option>
                        );
                    })
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading owner type options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default OwnerTypesSelect;
