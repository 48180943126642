import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { indicatorIndustryKeys } from "reactQuery/keys";

import { IndicatorIndustry } from "./apiTypes";

export const useGetIndicatorIndustries = ({
    enabled = true,
}: {
    enabled: boolean;
}): UseQueryResult<any> => {
    const queryKey = indicatorIndustryKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<IndicatorIndustry> =>
            (await nautilusGetRequest("/api/target_reports/industries/")).data,
        enabled,
    });
};
