import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";

import { BUTTON_STATUS } from "components/organisms/PaginatedTable/options";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useRequestDataExport } from "reactQuery/hooks/useTableData";

interface DataExportModalProps {
    defaultPageSize?: number;
    feedType: string;
    handleClose: () => void;
    setButtonStatus?: (status: string) => void;
    visible: boolean;
}

interface DataExportModalButtonProps {
    buttonStatus?: string;
    setOpenModal: () => void;
}

export const STATUS_MESSAGES = {
    initial: (email_address: string) =>
        `You are requesting an export. The first 100 rows of this view will be emailed as a .CSV file${
            email_address ? ` to ${email_address}` : ""
        }. Click export data to confirm this request.`,
    success: (email_address: string) =>
        `Your export request is being processed. A CSV file with your requested data will be sent${
            email_address ? ` to ${email_address}` : ""
        }.`,
    tooManyRequests: (minutes: number) =>
        `Too many data export requests. Please try again in ${minutes} minutes.`,
    genericError: "Unknown error",
};

const DataExportModal = ({
    defaultPageSize,
    feedType,
    handleClose,
    setButtonStatus,
    visible,
}: DataExportModalProps) => {
    const onModalCancel = () => {
        handleClose();
    };

    const [statusMessage, setStatusMessage] = useState<string>(null);
    const [statusIcon, setStatusIcon] = useState(null);
    const [dataExportRequestEnabled, setDataExportRequestEnabled] =
        useState<boolean>(false);

    const requestDataExportQuery = useRequestDataExport({
        feedType,
        defaultPageSize,
        enabled: dataExportRequestEnabled,
    });
    const whoamiQuery = useGetWhoami();

    useEffect(() => {
        if (requestDataExportQuery.data) {
            if (requestDataExportQuery.data.status === 200) {
                setStatusMessage(
                    STATUS_MESSAGES.success(whoamiQuery.data?.user?.email)
                );
                setButtonStatus && setButtonStatus(BUTTON_STATUS.SUCCESS);
                setStatusIcon(
                    <CheckCircleOutlined
                        style={{ color: "green", fontSize: 40, marginRight: 20 }}
                    />
                );
            } else if (requestDataExportQuery.data.status === 429) {
                const retryAfter = requestDataExportQuery.data.headers["retry-after"];
                const retryAfterMinutes = retryAfter && Math.round(retryAfter / 60);

                setStatusMessage(STATUS_MESSAGES.tooManyRequests(retryAfterMinutes));
                setStatusIcon(
                    <ClockCircleOutlined
                        style={{ color: "yellow", fontSize: 40, marginRight: 20 }}
                    />
                );
                // FUTURE TODO: Disable button, add a timer to reenable the button
            } else {
                setStatusMessage(STATUS_MESSAGES.genericError);
            }
        }
    }, [requestDataExportQuery.data]);

    return (
        <Modal
            open={visible}
            centered
            forceRender
            destroyOnClose={true}
            onCancel={onModalCancel}
            bodyStyle={{
                overflowY: "auto",
                minHeight: 175,
                maxHeight: "calc(100vh - 300px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            footer={
                <>
                    <Button key="cancel" type="default" onClick={onModalCancel}>
                        Close
                    </Button>
                    <Button
                        form={"table-filters-form"}
                        key="submit"
                        htmlType="submit"
                        type="primary"
                        onClick={() => setDataExportRequestEnabled(true)}
                        disabled={
                            dataExportRequestEnabled ||
                            (!dataExportRequestEnabled &&
                                requestDataExportQuery.isSuccess)
                        }
                    >
                        Export Data
                    </Button>
                </>
            }
        >
            <div
                style={{
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 18,
                }}
            >
                {statusMessage ? (
                    <>
                        {statusIcon}
                        <p>{statusMessage}</p>
                    </>
                ) : dataExportRequestEnabled && requestDataExportQuery.isLoading ? (
                    <p>Requesting export...</p>
                ) : (
                    <p>{STATUS_MESSAGES.initial(whoamiQuery.data?.user?.email)}</p>
                )}
            </div>
        </Modal>
    );
};

export const DataExportModalButton = ({
    buttonStatus,
    setOpenModal,
}: DataExportModalButtonProps) => {
    const buttonColor =
        buttonStatus === BUTTON_STATUS.SUCCESS
            ? { backgroundColor: "green", color: "white" }
            : buttonStatus === BUTTON_STATUS.ERROR
            ? { backgroundColor: "orange", color: "white" }
            : null;
    const buttonText =
        buttonStatus === BUTTON_STATUS.SUCCESS
            ? "Data Exported"
            : buttonStatus === BUTTON_STATUS.ERROR
            ? "Error"
            : "Data Export";
    return (
        <Button
            onClick={() => {
                setOpenModal();
            }}
            style={{ ...(buttonColor ? buttonColor : {}) }}
        >
            {buttonText}
        </Button>
    );
};

export default DataExportModal;
