import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { ProtectedRoute } from "components/organisms/ProtectedRoute";
import withAuthenticationCheck from "hocs/withAuthenticationCheck";
import Dashboard from "pages/Dashboard";
import Home from "pages/Home";
import LoginPage from "pages/LoginPage";
import OldReportDetailsPage from "pages/OldReportDetails";
import PasswordChange from "pages/PasswordChange";
import PasswordReset from "pages/PasswordReset";
import ProductNotFound from "pages/ProductNotFound";
import RequestPasswordReset from "pages/RequestPasswordReset";
import IntelligenceScopesPage, {
    IntelligenceScopesPathname,
} from "pages/SearchPlanForm/IntelligenceScopes";
import SearchPlanSummaryPage from "pages/SearchPlanSummary";
import Settings, { SettingsPathname } from "pages/Settings";
import SharedOpportunitiesPage from "pages/SharedOpportunities";
import SignUp from "pages/SignUp";
import { ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import { useGetAllProducts } from "reactQuery/hooks/pages/useSidebarLayout";
import DocumentCompareRoutes, { DocumentComparePathname } from "routes/DocumentCompare";
import IndicatorFeedsRoutes, {
    IndicatorFeedsBasePathname,
} from "routes/IndicatorFeeds";
import IntelligenceFeedsRoutes, {
    IntelligenceFeedsBasePathname,
} from "routes/IntelligenceFeeds";
import IntelligenceReportsRoutes from "routes/IntelligenceReports";
import LWRoutes, { LWFeedsBasePathname } from "routes/LW";

export const UnconnectedApp = () => {
    const getAllProductsQuery = useGetAllProducts();
    const allProductsLoaded = getAllProductsQuery.isSuccess;

    return (
        <Switch>
            {/* PROTECTED ROUTES - USER MUST BE LOGGED IN */}
            <ProtectedRoute exact path="/">
                <Home />
            </ProtectedRoute>

            <ProtectedRoute path="/intrpt">
                <IntelligenceReportsRoutes />
            </ProtectedRoute>

            <ProtectedRoute path={IndicatorFeedsBasePathname}>
                <IndicatorFeedsRoutes />
            </ProtectedRoute>

            <ProtectedRoute path={IntelligenceFeedsBasePathname}>
                <IntelligenceFeedsRoutes />
            </ProtectedRoute>

            <ProtectedRoute path={SettingsPathname}>
                <Settings />
            </ProtectedRoute>

            <ProtectedRoute exact path="/product-not-found">
                <ProductNotFound />
            </ProtectedRoute>

            <ProtectedRoute path={DocumentComparePathname}>
                <DocumentCompareRoutes />
            </ProtectedRoute>

            <ProtectedRoute path={LWFeedsBasePathname}>
                <LWRoutes />
            </ProtectedRoute>

            <ProtectedRoute exact path={IntelligenceScopesPathname}>
                <IntelligenceScopesPage />
            </ProtectedRoute>

            {/* ROUTES FOR DYNAMIC DASHBOARDS */}
            {allProductsLoaded
                ? getAllProductsQuery.data.map((product) => {
                      return product.product_type == ProductTypeEnum.DASHBOARD &&
                          product.path !== "" ? (
                          <ProtectedRoute exact key={product.path} path={product.path}>
                              <Dashboard products={[product]} />
                          </ProtectedRoute>
                      ) : null;
                  })
                : null}

            {/* LEGACY PROTECTED ROUTES - Maintained for redirects */}
            <ProtectedRoute exact path="/reports/feed/:id">
                {withRouter(withAuthenticationCheck(OldReportDetailsPage))}
            </ProtectedRoute>
            <ProtectedRoute exact path="/reports/:id">
                {withRouter(withAuthenticationCheck(OldReportDetailsPage))}
            </ProtectedRoute>
            <ProtectedRoute exact path="/reports">
                <Redirect to="/" />
            </ProtectedRoute>
            <ProtectedRoute exact path="/opportunities/:id">
                <Redirect to="/" />
            </ProtectedRoute>
            <ProtectedRoute exact path="/opportunities">
                {withRouter(withAuthenticationCheck(OldReportDetailsPage))}
            </ProtectedRoute>

            {/* UNPROTECTED ROUTES - USER DOESN'T NEED TO BE LOGGED IN */}
            <Route exact path="/login">
                <LoginPage />
            </Route>

            <Route exact path="/password_change">
                <PasswordChange />
            </Route>
            <Route
                exact
                path="/forgot_password"
                component={withRouter(RequestPasswordReset)}
            />
            <Route
                path="/password_reset" // Uid and reset token should be provided as query params
                component={withRouter(PasswordReset)}
            />

            <Route path="/signup" component={withRouter(SignUp)} />

            <Route exact path="/shared_opportunities/:id">
                <SharedOpportunitiesPage />
            </Route>

            <Route exact path="/search_plan_summary/:id">
                <SearchPlanSummaryPage />
            </Route>

            <Route
                exact
                path="/not-found"
                render={() => (
                    <>
                        <h1>Not Found</h1>
                        <p>The requested page could not be found</p>
                    </>
                )}
            />

            {allProductsLoaded ? (
                <Route path="*">
                    <Redirect to="/not-found" />
                </Route>
            ) : null}
        </Switch>
    );
};

export default UnconnectedApp;
