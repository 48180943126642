import { useQuery } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { featureFlagsKeys } from "reactQuery/keys";

const getAllFeatureFlags = async () => {
    return (await nautilusGetRequest("/api/feature_flags/")).data;
};

export const useGetAllFeatureFlags = () => {
    const queryKey = featureFlagsKeys.all();
    return useQuery({
        queryKey,
        staleTime: 0,
        cacheTime: 0,
        queryFn: getAllFeatureFlags,
    });
};
