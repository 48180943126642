import { FEED_TYPES } from "constants/feedTypes";

interface lwExpandableRowOptionsProps {
    expandedRowKeys: number[];
    feedType: string;
    viewResultOnClick: any;
}

const lwExpandableRowOptions = ({
    expandedRowKeys,
    feedType,
    viewResultOnClick,
}: lwExpandableRowOptionsProps) => {
    const linkedIplResult = (record) => {
        return (
            <div>
                <a
                    target="_blank"
                    href={`/admin/target_reports/intelligencepipelineresult/${record.ipl_result_id}/`}
                >
                    Linked IPL {record.ipl_result_id}
                </a>
            </div>
        );
    };

    const linkedInsight = (record) => {
        const linkedInsightDetails =
            feedType === FEED_TYPES.LW_ALL_INSIGHTS
                ? {
                      insight_id: record.id,
                      opportunity_id: record.opportunity_id,
                      team_id: record.team_id,
                  }
                : record.last_insight_link_details;

        return (
            <div>
                <a
                    target="_blank"
                    href={`/intrpt/opportunity?team_id=${linkedInsightDetails.team_id}&opportunity_id=${linkedInsightDetails.opportunity_id}`}
                >
                    Linked Insight {linkedInsightDetails.insight_id}
                </a>
            </div>
        );
    };
    return {
        expandedRowRender: (record: any) => {
            return (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignContent: "center",
                        gap: "10px",
                        marginLeft: "45px",
                    }}
                >
                    <div>
                        <a onClick={() => viewResultOnClick(record.id)}>View Result</a>
                    </div>
                    <div style={{ display: "flex" }}>
                        {record.url ? (
                            <div>
                                <a target="_blank" href={record.url}>
                                    Source
                                </a>
                            </div>
                        ) : null}
                        {record.url && record.public_url ? <>&nbsp;</> : null}
                        {record.public_url ? (
                            <div>
                                <a target="_blank" href={record.public_url}>
                                    (Converted Text File)
                                </a>
                            </div>
                        ) : null}
                    </div>
                    {feedType === FEED_TYPES.LW_ALL_INSIGHTS && record.ipl_result_id
                        ? linkedIplResult(record)
                        : null}
                    {feedType === FEED_TYPES.LW_ALL_INSIGHTS ||
                    record.last_insight_link_details
                        ? linkedInsight(record)
                        : null}
                </div>
            );
        },
        defaultExpandAllRows: true,
        expandedRowKeys: expandedRowKeys,
    };
};

export default lwExpandableRowOptions;
