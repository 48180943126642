// TODO: change wasteWaterFacility variables to facility
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

import companyReducer, { companyReducerName } from "stores/companies/companySlice";
import demographicReducer, {
    demographicReducerName,
} from "stores/demographics/demographicSlice";
import drinkingWaterSystemReducer, {
    drinkingWaterSystemReducerName,
} from "stores/drinkingWaterSystems/drinkingWaterSystemSlice";
import insightReducer, { insightReducerName } from "stores/insights/insightSlice";
import { opportunityReducerName } from "stores/opportunities/constants";
import opportunityReducer from "stores/opportunities/reducer";
import reportsReducer, { reportsReducerName } from "stores/reports/reportSlice";
import rootSaga from "stores/rootSaga";
import uiReducer, { uiReducerName } from "stores/uiStore/reducer";
import universeReducer, { universeReducerName } from "stores/universeStore/reducer";
import { wastewaterFacilitiesReducerName } from "stores/wastewaterFacilities/constants";
import wastewaterFacilitiesReducer from "stores/wastewaterFacilities/reducer";

import sourceReducer, { sourceReducerName } from "./sources/sourceSlice";
import tagReducer, { tagReducerName } from "./tags/tagSlice";
import userReducer, { userReducerName } from "./userStore/reducer";

const createRootReducer = (history) =>
    combineReducers({
        [companyReducerName]: companyReducer,
        [demographicReducerName]: demographicReducer,
        [drinkingWaterSystemReducerName]: drinkingWaterSystemReducer,
        [insightReducerName]: insightReducer,
        [opportunityReducerName]: opportunityReducer,
        [reportsReducerName]: reportsReducer,
        [sourceReducerName]: sourceReducer,
        [tagReducerName]: tagReducer,
        [uiReducerName]: uiReducer,
        [userReducerName]: userReducer,
        [universeReducerName]: universeReducer,
        [wastewaterFacilitiesReducerName]: wastewaterFacilitiesReducer,
        router: connectRouter(history),
    });
const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeWithDevTools(
            applyMiddleware(routerMiddleware(history), sagaMiddleware, thunk)
        )
    );
    sagaMiddleware.run(rootSaga);
    return store;
}
