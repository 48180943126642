import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { StateKeys } from "reactQuery/keys";

import { State } from "./apiTypes";

export const useGetStates = ({
    enabled = true,
}: {
    enabled?: boolean;
}): UseQueryResult<State[]> => {
    const queryKey = StateKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<State[]> =>
            (await nautilusGetRequest("/api/universe/states/")).data,
        enabled,
    });
};
