import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { queryKeys, queryTypeKeys } from "reactQuery/keys";

export const useSearchQueries = ({
    query,
    enabled,
}: {
    query: string;
    enabled: boolean;
}): UseQueryResult<any[]> => {
    const queryKey = queryKeys.search(query);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any[]> =>
            (
                await nautilusGetRequest(
                    "/api/target_reports/queries/",
                    `?limit=100&query_text=${query}`
                )
            ).data?.results,
        enabled,
    });
};

export const useGetQueryTypes = ({
    enabled = true,
}: {
    enabled?: boolean;
}): Partial<UseQueryResult<any>> => {
    const queryKey = queryTypeKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/query_types/`
            );
            return data;
        },
        enabled,
    });
};
