import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form, withFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import MarkdownTextField from "components/atoms/MarkdownTextField";
import { FormModal } from "components/molecules/Modal";
import { CONTENT_TYPES } from "constants/contentTypes";
import { formatMonthDayCommaYear } from "constants/formatters";
import { useCreateIndicatorGroup } from "reactQuery/hooks/useIndicatorGroups";
import { useGetAllIndicators } from "reactQuery/hooks/useIndicators";
import { useGetAllInitiativeTypes } from "reactQuery/hooks/useInitiativeTypes";
import { createInsight, newInsightSelector } from "stores/insights/insightSlice";
import {
    activeReportSelector,
    reportsForActiveTeamSelector,
} from "stores/reports/reportSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const getIndicatorOptionLabel = ({ value, category }) =>
    `${value} (${category})`;

export const getReportOptionLabel = ({ date, status }) => {
    const formattedDate = formatMonthDayCommaYear(date);
    return `${formattedDate} - ${status}`;
};

const formStyle = makeStyles((theme) => ({
    form: {
        "&> div": {
            paddingBottom: theme.spacing(2),
            width: "100%",
        },
        "&> div:last-child": {
            paddingBottom: theme.spacing(0),
        },
    },
    formControl: {
        width: "100%",
        marginBottom: "20px",
    },
}));

export const ERROR_MESSAGES = {
    reportMissing: "A report is required",
    indicatorIndustryMissing: "An indicator industry is required",
    indicatorCategoryMissing: "An indicator category is required",
    indicatorMissing: "An indicator is required",
    triggerSummaryMissing: "A trigger summary is required",
};

const aNumber = (value) => typeof value === "number";

const createInsightSchema = Yup.object().shape({
    report: Yup.object().nullable().required(ERROR_MESSAGES.reportMissing),
    triggerSummary: Yup.string().required(ERROR_MESSAGES.triggerSummaryMissing),
    // TODO: duplicated with EditInsightModal, maybe move to helper file?
    selectedIndicator: Yup.object().shape({
        industry_id: Yup.number().required(ERROR_MESSAGES.indicatorIndustryMissing),
        category_id: Yup.number().when("industry_id", {
            is: aNumber,
            then: Yup.number().required(ERROR_MESSAGES.indicatorCategoryMissing),
            otherwise: Yup.number().notRequired(),
        }),
        id: Yup.number().when("category_id", {
            is: aNumber,
            then: Yup.number().required(ERROR_MESSAGES.indicatorMissing),
            otherwise: Yup.number().notRequired(),
        }),
    }),
});

export const CreateInsightForm = ({
    errors,
    handleChange,
    reports,
    setFieldValue,
    values,
}) => {
    const {
        report,
        triggerSummary,
        selectedIndicator,
        selectedIndicatorTags,
        selectedInitiativeTypes,
    } = values;

    const classes = formStyle();

    const getAllInitiativeTypesQuery = useGetAllInitiativeTypes({});
    const getAllIndicatorsQuery = useGetAllIndicators();

    return (
        <Form>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    loading={!reports}
                    options={reports || []}
                    getOptionLabel={getReportOptionLabel}
                    onChange={(e, value) => {
                        setFieldValue("report", value);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="report"
                            label="Report"
                            variant="outlined"
                            error={"report" in errors}
                            helperText={errors.report || null}
                        />
                    )}
                    value={report}
                />
            </FormControl>

            <MarkdownTextField
                error={"triggerSummary" in errors}
                helperText={errors.triggerSummary || null}
                id="trigger-summary"
                name="triggerSummary"
                onChange={handleChange}
                label="Trigger Summary"
                value={triggerSummary}
            />

            <p>Indicator</p>

            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            ...new Map(
                                (getAllIndicatorsQuery.data || []).map((indicator) => [
                                    indicator["industry_id"],
                                    indicator,
                                ])
                            ).values(),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.industry || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["industry_id"] === value["industry_id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                industry_id: value?.["industry_id"],
                                industry: value?.["industry"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-industry"
                                label="Indicator Industry"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.industry_id}
                                helperText={
                                    errors.selectedIndicator?.industry_id || null
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        disabled={!selectedIndicator?.["industry"]}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            selectedIndicator,
                            ...new Map(
                                (getAllIndicatorsQuery.data || [])
                                    .filter(
                                        (indicator) =>
                                            indicator["industry_id"] ===
                                            selectedIndicator?.["industry_id"]
                                    )
                                    .map((indicator) => [
                                        indicator["category_id"],
                                        indicator,
                                    ])
                            ).values(),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.category || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["category_id"] === value["category_id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                industry_id: selectedIndicator["industry_id"],
                                industry: selectedIndicator["industry"],
                                category_id: value?.["category_id"],
                                category: value?.["category"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-category"
                                label="Indicator Category"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.category_id}
                                helperText={
                                    errors.selectedIndicator?.category_id || null
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        disabled={!selectedIndicator?.["category"]}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            selectedIndicator,
                            ...((getAllIndicatorsQuery.data || []).filter(
                                (indicator) =>
                                    indicator["category_id"] ===
                                    selectedIndicator?.["category_id"]
                            ) ?? []),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                ...selectedIndicator,
                                value: value?.["value"],
                                id: value?.["id"],
                                indicator_tags: value?.["indicator_tags"],
                                initiative_types: value?.["initiative_types"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator"
                                label="Indicator"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.id}
                                helperText={errors.selectedIndicator?.id || null}
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicatorTags}
                        disabled={!selectedIndicator?.["id"]}
                        multiple={true}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={selectedIndicator?.["indicator_tags"] || []}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicatorTags", value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-tags"
                                label="Indicator Tags"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedInitiativeTypes}
                        multiple={true}
                        loading={getAllInitiativeTypesQuery.isLoading}
                        options={getAllInitiativeTypesQuery.data || []}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedInitiativeTypes", value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-initiative-types"
                                label="Initiative Types"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

export const UnconnectedCreateInsightModal = ({
    isCreatingNewInsight,
    isVisible,
    reports,
    toggleOverlay,
    ...formikProps
}) => {
    formikProps.values.createIndicatorGroupMutation = useCreateIndicatorGroup();

    return (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.createInsightModal });
            }}
            handleSubmit={formikProps.handleSubmit}
            isSubmitDisabled={isCreatingNewInsight}
            isSubmitLoading={isCreatingNewInsight}
            isVisible={isVisible}
            hasSecondaryButton={false}
            submitLabel="Submit"
            title="Create New Insight"
        >
            <CreateInsightForm reports={reports} {...formikProps} />
        </FormModal>
    );
};

export const EnhancedCreateInsightModal = withFormik({
    enableReinitialize: true,
    handleSubmit: async (
        {
            report,
            triggerSummary,
            selectedIndicator,
            selectedIndicatorTags,
            selectedInitiativeTypes,
            createIndicatorGroupMutation,
        },
        { props: { createInsight, opportunityId } }
    ) => {
        const indicatorTagIds = selectedIndicatorTags.map(
            (indicatorTag) => indicatorTag.id
        );
        const initiativeTypeIds = selectedInitiativeTypes.map(
            (initiativeType) => initiativeType.id
        );
        const response = await createInsight({
            opportunityId,
            reportId: report.id,
            triggerSummary,
        });

        createIndicatorGroupMutation.mutate({
            content_type: CONTENT_TYPES.INSIGHT,
            indicator: selectedIndicator.id,
            indicator_tags: indicatorTagIds,
            initiative_types: initiativeTypeIds,
            object_id: response.payload.id,
        });
    },
    mapPropsToValues: ({ activeReport }) => ({
        report: activeReport || null,
        triggerSummary: "",
        selectedIndicator: {},
        selectedIndicatorTags: [],
        selectedInitiativeTypes: [],
        createIndicatorGroupMutation: null,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: createInsightSchema,
})(UnconnectedCreateInsightModal);

const mapStateToProps = (state) => {
    const newInsight = newInsightSelector(state);
    const isShowing = isShowingSelector(state);

    return {
        isCreatingNewInsight: newInsight.isCreating,
        isVisible: isShowing.createInsightModal,
        opportunityId: newInsight.opportunityId,
        reports: reportsForActiveTeamSelector(state),
        activeReport: activeReportSelector(state),
    };
};

const ConnectedCreateInsightModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
    createInsight,
})(EnhancedCreateInsightModal);

export default ConnectedCreateInsightModal;
