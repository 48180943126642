import { useMediaQuery } from "@material-ui/core";
import { Button, Input, Space } from "antd";
import { useEffect, useState, ReactNode, useRef } from "react";
import { useHistory } from "react-router-dom";

import { Text, StyledButton } from "components/atoms";
import { SelectedItemsButton } from "components/cdv/SelectedItemsButton";
import ShareOpportunitiesModal from "components/cdv/ShareOpportunitiesModal";
import { SyncOpportunitiesToCRMModal } from "components/cdv/SyncOpportunitiesToCRMModal";
import { AssignIPLResultsModal } from "components/molecules/AssignIPLResultsModal";
import DataExportModal, {
    DataExportModalButton,
} from "components/molecules/DataExportModal";
import ViewDetailsModal from "components/molecules/ViewDetailsModal";
import { FEED_TYPES } from "constants/feedTypes";
import { PaginatedTableInsight } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useManagedQueryParams } from "utils/useManagedQueryParams";

import insightExpandableRowOptions from "./expandableRows/insightExpandableRow";
import lwExpandableRowOptions from "./expandableRows/lwExpandableRow";
import { getColumns } from "./getColumns";
import FiltersModal from "./modals/filtersModal";
import { MODALS } from "./options";
import { StyledTable, useStyles } from "./styles";
import { CombinedTableInsightRecord, PaginatedTableInsightRecord } from "./types";
import { useTableDependencies } from "./useTableDependencies";

const PaginatedTable = ({
    contentId,
    feedType,
    teamId,
    disablePagination = false,
}: {
    contentId?: number;
    feedType: string;
    teamId: number;
    disablePagination?: boolean;
}) => {
    const getWhoamiQuery = useGetWhoami();
    const isStaff = getWhoamiQuery.isSuccess
        ? getWhoamiQuery.data.user.is_staff
        : false;

    const history = useHistory();
    const [recordsCount, setRecordsCount] = useState<number>();
    const [searchDisplayValue, setSearchDisplayValue] = useState<string>(null);
    const searchTextValueRef = useRef<string>(null);
    const [selectedRows, setSelectedRows] = useState<CombinedTableInsightRecord[]>([]);
    const [filtersEnabled, setFiltersEnabled] = useState<boolean>(false);
    const [modalVisibility, setModalVisibility] = useState<string>(null);
    const [buttonStatus, setButtonStatus] = useState<Record<string, string>>({});
    const [selectedItemId, setSelectedItemId] = useState<number>(null);

    const styles = useStyles();

    const {
        associatedDataQueries,
        dataQuery,
        defaultFilters,
        defaultSorter,
        enableFiltersQuery,
        filtersQuery,
        hasDefaultFilterSort,
        itemIdPages,
        itemIds,
        itemIdsLoading,
        modalFilters,
        mutateDataQueries,
        relevantId,
        showLoadFiltersButton,
        syncedDefaultFilters,
        tableOptions,
        setSyncedDefaultFilters,
    } = useTableDependencies({
        feedType,
        contentId,
        teamId,
        filtersEnabled,
    });
    const filters = filtersQuery?.data;
    const isLoadingFilters = enableFiltersQuery && filtersQuery?.isLoading;

    const {
        defaultPagination,
        filteredInfo,
        hasActiveFilters,
        paginatedInfo,
        searchTextValue,
        sortedInfo,
        initializeHook,
        updateQuery,
    } = useManagedQueryParams(
        tableOptions.defaultPageSize,
        tableOptions.pageSizeOptions
    );

    searchTextValueRef.current = searchTextValue;

    const data: PaginatedTableInsightRecord[] = (dataQuery.data?.results ?? []).map(
        (insight: PaginatedTableInsight) => ({
            key: insight.id,
            ...insight,
        })
    );
    const isLoadingData = dataQuery?.isLoading;

    const currentPage = paginatedInfo.current ?? defaultPagination.current;
    useEffect(() => {
        if (dataQuery.isSuccess) {
            setRecordsCount(dataQuery.data?.count);
            if (
                currentPage > 1 &&
                dataQuery.data?.count <= paginatedInfo.pageSize * (currentPage - 1)
            ) {
                handleTableChange({
                    ...paginatedInfo,
                    current: 1,
                });
            }
        }
    }, [dataQuery.data]);

    useEffect(() => {
        if (hasDefaultFilterSort && !syncedDefaultFilters) {
            // If the view has default filters, it is necessary to "reset" the hook which manages query params/objs, since they will no longer
            // be in sync once updated with the defaults
            initializeHook();
            setSyncedDefaultFilters(true);
        }
    }, [hasDefaultFilterSort]);

    useEffect(() => {
        setSearchDisplayValue(searchTextValue);
    }, [searchTextValue]);

    useEffect(() => {
        if (selectedItemId) {
            changeTablePageToMatchSelectedItem(selectedItemId);
        }
    }, [selectedItemId]);

    const clearFilters = () => {
        updateQuery({
            updatedFilters: defaultFilters || {},
            updatedSorters: defaultSorter || {},
        });
    };

    const clearSearch = () => {
        updateQuery({
            updatedSearchText: "",
        });
        setSearchDisplayValue(null);
    };

    const clearSelection = () => {
        setSelectedRows([]);
    };

    const clearSorters = () => {
        updateQuery({
            updatedSorters: {},
            includeExistingParams: false,
        });
    };

    const changeTablePageToMatchSelectedItem = (selectedItemId) => {
        if (!recordsCount || itemIdsLoading) {
            return;
        }
        const currentPageIndex = itemIdPages?.current?.indexOf(selectedItemId);
        if (currentPageIndex === -1) {
            const nextPageIndex = itemIdPages?.next?.indexOf(selectedItemId);
            if (nextPageIndex === -1) {
                const previousPageIndex =
                    itemIdPages?.previous?.indexOf(selectedItemId);
                if (previousPageIndex === -1) {
                    handleTableChange({
                        ...paginatedInfo,
                        current: 1,
                    });
                } else {
                    handleTableChange({
                        ...paginatedInfo,
                        current: currentPage - 1,
                    });
                }
            } else {
                handleTableChange({
                    ...paginatedInfo,
                    current: currentPage + 1,
                });
            }
        }
    };

    const handleTableChange = (
        pagination = null,
        filters = null,
        sorter = null,
        source = null
    ) => {
        if (pagination && pagination.current !== paginatedInfo.current) {
            setSelectedRows([]);
        }
        updateQuery({
            updatedFilters:
                modalFilters && source === "tableComponent" ? null : filters,
            updatedPagination: pagination,
            updatedSearchText: searchTextValueRef.current,
            updatedSorters: modalFilters && source === "tableComponent" ? null : sorter,
        });
    };

    const onSelectChanged = (
        _newSelectedRowKeys: React.Key[],
        newSelectedRows: PaginatedTableInsightRecord[]
    ) => {
        setSelectedRows(newSelectedRows);
    };

    const search = (searchText: string) => {
        updateQuery({
            updatedSearchText: searchText || null,
        });
    };

    const expandedRowKeys =
        data && data.length > 0 ? data.map((record) => record.key) : [];
    const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
    const mobileColumns = ["state_code", "opportunity_owner"];

    const columns = getColumns({
        associatedDataQueries,
        feedType,
        filteredInfo,
        filters,
        history,
        isLoadingFilters,
        mutateDataQueries,
        searchTextValue,
        sortedInfo,
        styles,
        tableOptions,
    });

    const bulkActions =
        !isLoadingData && tableOptions.showBulkActions ? (
            <div>
                <SelectedItemsButton
                    selectedCount={selectedRows.length}
                    disabled={!selectedRows.length}
                    teamId={teamId}
                    assignIPLResultOnClick={() =>
                        setModalVisibility(MODALS.ASSIGN_IPL_RESULTS)
                    }
                    shareOpportunitiesOnClick={() =>
                        setModalVisibility(MODALS.SHARE_OPPORTUNITIES)
                    }
                    syncOpportunitiesToCRMOnClick={() =>
                        setModalVisibility(MODALS.SYNC_TO_CRM)
                    }
                    itemName={tableOptions.recordTitle}
                    itemNamePlural={tableOptions.recordTitlePlural}
                    options={tableOptions.bulkActionOptions}
                />
                <Button onClick={clearSelection}>Clear Selection</Button>
            </div>
        ) : (
            <div></div>
        );

    const dataExportButton =
        tableOptions.showDataExportButton &&
        (!isLoadingData ||
            filtersQuery?.isSuccess ||
            modalFilters ||
            buttonStatus[MODALS.DATA_EXPORT]) ? (
            <DataExportModalButton
                buttonStatus={buttonStatus[MODALS.DATA_EXPORT]}
                setOpenModal={() => {
                    setModalVisibility(MODALS.DATA_EXPORT);
                }}
            />
        ) : null;

    return (
        <>
            <AssignIPLResultsModal
                defaultPageSize={tableOptions.defaultPageSize}
                feedType={feedType}
                handleClose={() => setModalVisibility(null)}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                visible={modalVisibility === MODALS.ASSIGN_IPL_RESULTS}
            />
            <DataExportModal
                defaultPageSize={tableOptions.defaultPageSize}
                feedType={feedType}
                handleClose={() => setModalVisibility(null)}
                setButtonStatus={(buttonStatus) =>
                    setButtonStatus((currentButtonStatus) => ({
                        ...currentButtonStatus,
                        [MODALS.DATA_EXPORT]: buttonStatus,
                    }))
                }
                visible={modalVisibility === MODALS.DATA_EXPORT}
            />
            <FiltersModal
                clearFilters={clearFilters}
                feedType={feedType}
                filters={filteredInfo}
                filtersTemplate={tableOptions.filtersTemplate}
                handleClose={() => setModalVisibility(null)}
                setFilters={(filters) => handleTableChange(null, filters)}
                visible={modalVisibility === MODALS.FILTERS}
            />
            <ShareOpportunitiesModal
                handleClose={() => setModalVisibility(null)}
                opportunityCount={selectedRows.length}
                opportunityIds={selectedRows.map((insight) => insight.opportunity_id)}
                reportId={feedType === FEED_TYPES.SINGLE_REPORT ? relevantId : null}
                teamId={teamId}
                visible={modalVisibility === MODALS.SHARE_OPPORTUNITIES}
            />
            <SyncOpportunitiesToCRMModal
                feedType={feedType}
                handleClose={() => setModalVisibility(null)}
                indicatorGroupId={
                    feedType === FEED_TYPES.INDICATOR_FEED ? relevantId : null
                }
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                teamId={teamId}
                visible={modalVisibility === MODALS.SYNC_TO_CRM}
            />
            <ViewDetailsModal
                defaultPageSize={tableOptions.defaultPageSize}
                handleClose={() => setModalVisibility(null)}
                selectedItemId={selectedItemId}
                setSelectedItemId={setSelectedItemId}
                itemTitle={tableOptions.viewDetailsModalTitle}
                itemIds={itemIds}
                itemIdsLoading={itemIdsLoading}
                feedType={feedType}
                teamId={teamId}
                visible={modalVisibility === MODALS.VIEW_DETAILS}
            />

            {recordsCount && tableOptions.showRecordsCount !== undefined ? (
                <div className={styles.filtersContainer}>
                    <Text>
                        {recordsCount === 1
                            ? `${recordsCount} ${
                                  tableOptions.recordTitle ?? "record"
                              } found.`
                            : `${recordsCount} ${
                                  tableOptions.recordTitlePlural
                                      ? tableOptions.recordTitlePlural
                                      : tableOptions.recordTitle
                                      ? `${tableOptions.recordTitle}s`
                                      : "records"
                              } found.`}
                    </Text>
                </div>
            ) : null}

            {modalFilters ? (
                <>
                    <Space className={styles.filtersButtonContainer}>
                        {bulkActions}
                        <Space className={styles.rightButtonContainer}>
                            {dataExportButton}
                            <StyledButton
                                variant={
                                    hasActiveFilters ? "edit-primary" : "add-primary"
                                }
                                handleClick={() => {
                                    setModalVisibility(MODALS.FILTERS);
                                }}
                            >
                                Filters
                            </StyledButton>
                        </Space>
                    </Space>
                </>
            ) : filtersQuery.isSuccess ? (
                <>
                    <Space className={styles.filtersButtonContainer}>
                        <Space>
                            {bulkActions}
                            <Button onClick={clearFilters}>Clear Filters</Button>
                            <Button onClick={clearSorters}>Clear Sorters</Button>
                        </Space>
                        <Space className={styles.rightButtonContainer}>
                            <Input.Search
                                data-testid="search"
                                style={{ width: 304 }}
                                placeholder="Search by..."
                                enterButton
                                allowClear
                                onSearch={search}
                                onChange={(e) =>
                                    e.target.value
                                        ? setSearchDisplayValue(e.target.value)
                                        : clearSearch()
                                }
                                value={searchDisplayValue}
                            />
                            <Button
                                onClick={clearSearch}
                                style={{ float: "right", marginLeft: 5 }}
                            >
                                Clear Search
                            </Button>
                            {dataExportButton}
                        </Space>
                    </Space>
                </>
            ) : showLoadFiltersButton ? (
                <div className={styles.loadTableFilters}>
                    <StyledButton
                        variant="add-primary"
                        handleClick={() => {
                            setFiltersEnabled(true);
                        }}
                    >
                        Load Table Filters
                    </StyledButton>
                    {dataExportButton}
                </div>
            ) : isLoadingFilters ? (
                <Space className={styles.filtersButtonContainer}>
                    <Text>
                        <span className={styles.loading}>
                            Loading filters, sorting and search
                        </span>
                    </Text>
                    {dataExportButton}
                </Space>
            ) : null}
            <StyledTable
                key="opportunity"
                columns={
                    isSmallScreen
                        ? columns.filter((column) => mobileColumns.includes(column.key))
                        : columns
                }
                loading={{
                    spinning: isLoadingData,
                }}
                expandable={
                    tableOptions.expandableRow === "insight"
                        ? insightExpandableRowOptions({
                              defaultPageSize: tableOptions.defaultPageSize,
                              expandedRowKeys,
                              feedType,
                              insightTagTitle: tableOptions.insightTagTitle,
                              isStaff,
                              styles,
                              teamId,
                              buttonParams:
                                  tableOptions.clickParams?.expandableRowButton,
                          })
                        : tableOptions.expandableRow === "lw"
                        ? lwExpandableRowOptions({
                              expandedRowKeys,
                              feedType,
                              viewResultOnClick: (recordId) => {
                                  setSelectedItemId(recordId);
                                  setModalVisibility(MODALS.VIEW_DETAILS);
                              },
                          })
                        : {
                              expandedRowRender: () => <Space size={20}></Space>,
                              defaultExpandAllRows: true,
                              expandedRowKeys: expandedRowKeys,
                          }
                }
                dataSource={data}
                locale={{
                    emptyText:
                        !isLoadingData && tableOptions.emptyDataDisplay
                            ? tableOptions.emptyDataDisplay
                            : null,
                }}
                onChange={(pagination, filters, sorter) =>
                    handleTableChange(pagination, filters, sorter, "tableComponent")
                }
                tableLayout={"auto"}
                pagination={
                    disablePagination
                        ? false
                        : {
                              current: currentPage,
                              pageSize:
                                  paginatedInfo.pageSize ?? defaultPagination.pageSize,
                              pageSizeOptions: defaultPagination.pageSizeOptions,
                              showQuickJumper: true,
                              showSizeChanger: true,
                              showTotal: (total: number, range: [number, number]) =>
                                  `${range[0]}-${range[1]} of ${total} items`,
                              total: recordsCount,
                          }
                }
                rowSelection={
                    tableOptions.showBulkActions
                        ? {
                              selectedRowKeys: selectedRows.map(
                                  (insight) => insight.key
                              ),
                              type: "checkbox",
                              onChange: onSelectChanged,
                              renderCell(
                                  _checked: boolean,
                                  record: CombinedTableInsightRecord,
                                  _index: number,
                                  node: ReactNode
                              ) {
                                  return (
                                      <span
                                          data-testid={`row-selection-row-${record.key}`}
                                      >
                                          {node}
                                      </span>
                                  );
                              },
                          }
                        : null
                }
            />
        </>
    );
};

export default PaginatedTable;
