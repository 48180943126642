import { Checkbox, FormControlLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Form, withFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import MarkdownTextField from "components/atoms/MarkdownTextField";
import { FormModal } from "components/molecules/Modal";
import {
    editInsightSelector,
    insightBeingEditedSelector,
    patchInsight,
    removeInsight,
    removeInsightSelector,
} from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

const editInsightSchema = Yup.object().shape({
    triggerSummary: Yup.string(),
});

const removeInsightStyle = makeStyles(() => ({
    label: {
        color: "grey",
    },
}));

const editInsightStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
}));

export const enableAISummaryCheckboxLabel =
    "Let AI auto-regenerate the insight summary whenever the indicator, source trigger extract, or the opportunity owner changes.";

export const RemoveInsight = ({ insight }) => {
    const classes = removeInsightStyle();

    return (
        <div>
            <p>
                Are you sure that you would like to remove this insight from this
                opportunity? Once removed, this action cannot be reversed.
            </p>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <p className={classes.label}>Trigger Summary:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{insight ? insight.summary : ""}</p>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const EditInsightForm = ({ errors, handleChange, values }) => {
    const { triggerSummary, aiSummaryEnabled } = values;
    const classes = editInsightStyles();

    return (
        <Form className={classes.formControl}>
            <MarkdownTextField
                errors={"triggerSummary" in errors}
                helperText={errors.triggerSummary || null}
                id="trigger-summary"
                name="triggerSummary"
                onChange={handleChange}
                label="Trigger Summary"
                value={triggerSummary}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={aiSummaryEnabled}
                        onChange={handleChange}
                        name="aiSummaryEnabled"
                    />
                }
                label={enableAISummaryCheckboxLabel}
            />
        </Form>
    );
};

export const UnconnectedEditInsightModal = ({
    insight,
    isShowingModal,
    isUpdating,
    isRemoving,
    toggleOverlay,
    ...formikProps
}) => {
    const showRemove =
        formikProps && formikProps.values ? formikProps.values.isShowingRemove : false;
    if (!isShowingModal) {
        return null;
    }

    const props = {
        values: formikProps.values,
        errors: formikProps.errors,
        handleChange: formikProps.handleChange,
    };

    return (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.editInsightModal });
            }}
            handleSubmit={() => {
                formikProps.handleSubmit();
            }}
            isSecondaryDisabled={isUpdating || isRemoving}
            isSubmitDisabled={isUpdating || isRemoving}
            isSubmitLoading={isUpdating || isRemoving}
            isVisible={isShowingModal}
            hasSecondaryButton={true}
            handleSecondaryClick={() => {
                formikProps.setFieldValue("isShowingRemove", !showRemove);
            }}
            title={!showRemove ? "Edit Insight" : "Remove Insight"}
            isEditModeActive={true}
            isRemoveModeActive={showRemove}
        >
            {showRemove && <RemoveInsight insight={insight} />}
            {!showRemove && <EditInsightForm {...props} />}
        </FormModal>
    );
};

export const EnhancedEditInsightModal = withFormik({
    enableReinitialize: true,
    handleSubmit: (
        { triggerSummary, isShowingRemove, aiSummaryEnabled },
        {
            // ignore the following TS issues for now since this component should be refactored
            // to use react query and ant design
            props: {
                // @ts-ignore
                insight: { id },
                // @ts-ignore
                patchInsight,
            },
        }
    ) => {
        if (isShowingRemove) {
            patchInsight({
                insightId: id,
                is_deleted: true,
            });
        } else {
            patchInsight({
                insightId: id,
                summary: triggerSummary,
                ai_summary_enabled: aiSummaryEnabled,
            });
        }
    },
    // @ts-ignore - ignore this issue for now since this component should be refactored
    // to use react query and ant design
    mapPropsToValues: ({ insight }) => ({
        triggerSummary: insight ? insight.summary : "",
        isShowingRemove: false,
        aiSummaryEnabled: insight ? insight.ai_summary_enabled : false,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: editInsightSchema,
})(UnconnectedEditInsightModal);

const mapStateToProps = (state) => {
    const editInsight = editInsightSelector(state);
    const removeInsight = removeInsightSelector(state);
    const isShowing = isShowingSelector(state);

    return {
        insight: insightBeingEditedSelector(state as never),
        isShowingModal: isShowing.editInsightModal,
        isUpdating: editInsight.isUpdating,
        isRemoving: removeInsight.isRemoving,
    };
};
export const ConnectedEditInsightModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
    patchInsight,
    removeInsight,
})(EnhancedEditInsightModal);

export default ConnectedEditInsightModal;
