import { Form, Select } from "antd";
import { useEffect, useState } from "react";

import { useSearchDebounced } from "components/helpers/debounce";
import { useGetIds } from "reactQuery/hooks/useGetIds";

interface AutocompleteFormSelectProps {
    allowArbitrary?: boolean;
    allowMultiple?: boolean;
    description?: string;
    disabled?: boolean;
    filterOption?: any;
    filters?: any;
    form?: any;
    highlight?: any;
    id: string;
    label?: string;
    optionFilterProp?: string;
    options?: any;
    placeholderText?: string;
    retrieveIds?: boolean;
    searchAsUserTypes?: boolean;
    searchQuery?: any;
    textKey?: string;
    textTemplate?: string;
    valueKey?: string;
}

const AutocompleteFormSelect = ({
    allowArbitrary = false,
    allowMultiple = false,
    description = null,
    disabled = false,
    filterOption = null,
    filters = null,
    form = null,
    highlight = null,
    id,
    label = "",
    optionFilterProp = "children",
    options = null,
    placeholderText = "Enter text to search...",
    retrieveIds = true,
    searchAsUserTypes = false,
    searchQuery = null,
    textKey = "value",
    textTemplate = null,
    valueKey = "id",
}: AutocompleteFormSelectProps) => {
    const [availableData, setAvailableData] = useState([]);
    const [initValueRetrieved, setInitValueRetrieved] = useState<boolean>(false);
    const [initValueSet, setInitValueSet] = useState<boolean>(false);
    const [userInteraction, setUserInteraction] = useState<boolean>(false);
    const [searchQueryString, setSearchQueryString] = useState<string>("");
    const queryMinChars = 1;
    const queryEnabled =
        !searchAsUserTypes || searchQueryString.length >= queryMinChars;
    const debounceSearch = useSearchDebounced(setSearchQueryString);
    const queryParams = {
        enabled: queryEnabled,
    };
    searchAsUserTypes && (queryParams["query"] = searchQueryString);

    const optionsQuery = searchQuery && searchQuery(queryParams);
    const getIdsMutation = useGetIds();

    const optionsData = optionsQuery?.data
        ? Array.isArray(optionsQuery.data)
            ? optionsQuery.data
            : typeof optionsQuery.data === "object"
            ? Object.entries(optionsQuery.data).map(([key, value]) => ({
                  ...(typeof value === "object" && value !== null
                      ? value
                      : { [textKey]: value }),
                  [valueKey]: key,
              }))
            : null
        : null;
    const getIdsData = getIdsMutation.data?.[id];
    const formValue = form?.getFieldValue(id)
        ? Array.isArray(form.getFieldValue(id))
            ? form.getFieldValue(id)
            : [form.getFieldValue(id)]
        : [];

    const initValue = filters ? (allowMultiple ? filters[id] : [filters[id]]) : [];

    const retrievedValue = (record) => {
        let title = "";
        if (textTemplate) {
            // This templating allows us to use templates like this:
            // "Company name: ${legal_name}. State: ${state_code}"
            // We can also use conditionals, like this: "Company name: ${legal_name}.[[ State: ${state_code}]]" (the entire square bracket part is conditional on state_code)
            title = textTemplate
                .replace(/\[\[(.*?)\]\]/g, (match, conditionalContent) => {
                    const hasAllKeys = conditionalContent
                        .match(/\${([^}]+)}/g)
                        ?.every((placeholder) => {
                            const key = placeholder.match(/\${([^}]+)}/)[1].trim();
                            return record[key];
                        });
                    return hasAllKeys
                        ? conditionalContent.replace(/\${([^}]+)}/g, (_, key) => {
                              const trimmedKey = key.trim();
                              return record[trimmedKey] ? record[trimmedKey] : "";
                          })
                        : "";
                })
                .replace(/\${([^}]+)}/g, (_, key) => {
                    const trimmedKey = key.trim();
                    return record[trimmedKey] ? record[trimmedKey] : "";
                });
        } else {
            title = record[textKey];
        }

        return title || record.id;
    };

    const setInitValues = () => {
        if (!getIdsData || formValue?.[0]?.label) {
            return;
        }
        const idData = getIdsData.flatMap((idData) =>
            formValue?.includes(idData[valueKey])
                ? [
                      {
                          ...idData,
                          value: idData[valueKey],
                          label: retrievedValue(idData),
                      },
                  ]
                : []
        );
        setAvailableData(idData);
        form.setFieldsValue({
            [id]: idData,
        });
    };

    useEffect(() => {
        if (queryEnabled && !userInteraction) {
            setUserInteraction(true);
        }
    }, [queryEnabled]);

    useEffect(() => {
        if (initValue && !queryEnabled) {
            if (
                Array.isArray(initValue) &&
                (initValue[0] === undefined || initValue[0] === null)
            ) {
                return;
            }
            if (!optionsData && !initValueRetrieved) {
                setInitValueRetrieved(true);
                if (retrieveIds) {
                    getIdsMutation.mutate({
                        [id]: Array.isArray(initValue) ? initValue : [initValue],
                    });
                }
            }
            if (getIdsData?.length && !initValueSet) {
                setInitValueSet(true);
                setInitValues();
            }
        }
    }, [initValue, getIdsData]);

    useEffect(() => {
        if (!userInteraction && initValue) {
            setInitValues();
        }
    }, [formValue]);

    useEffect(() => {
        if (optionsData) {
            setAvailableData((state) => [...optionsData, ...state]);
        }
    }, [optionsQuery?.data]);

    return (
        <>
            {highlight ? <>{highlight}</> : null}
            <Form.Item extra={description} label={label} name={id}>
                {options ? (
                    <Select
                        defaultValue={placeholderText}
                        options={options}
                        optionFilterProp="label"
                    />
                ) : (
                    <Select
                        allowClear={true}
                        disabled={disabled}
                        filterOption={
                            filterOption
                                ? filterOption
                                : searchAsUserTypes
                                ? false
                                : true
                        }
                        mode={
                            allowArbitrary ? "tags" : allowMultiple ? "multiple" : null
                        }
                        onSearch={(query) => debounceSearch(query)}
                        optionFilterProp={optionFilterProp}
                        placeholder={placeholderText}
                        showSearch
                        {...(allowArbitrary || (allowMultiple && searchAsUserTypes)
                            ? { tokenSeparators: [","], notFoundContent: null }
                            : {})}
                    >
                        {allowArbitrary || !queryEnabled ? (
                            <Select.Option value="disabled" disabled>
                                {placeholderText}
                            </Select.Option>
                        ) : optionsQuery.isLoading ? (
                            <Select.Option value="disabled" disabled>
                                Loading...
                            </Select.Option>
                        ) : optionsQuery.isSuccess ? (
                            (availableData || []).map((record) => {
                                const title = retrievedValue(record);
                                return (
                                    <Select.Option
                                        key={record?.[valueKey]}
                                        value={record?.[valueKey]}
                                        title={title}
                                    >
                                        {title}
                                    </Select.Option>
                                );
                            })
                        ) : (
                            <Select.Option value="disabled" disabled>
                                There was a problem loading options
                            </Select.Option>
                        )}
                    </Select>
                )}
            </Form.Item>
        </>
    );
};

export default AutocompleteFormSelect;
