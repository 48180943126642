import { useMutation, useQueryClient } from "@tanstack/react-query";

import { nautilusPostRequest } from "network";
import { idKeys } from "reactQuery/keys";

const getIdsRequest = async (requestedIds: any) => {
    return (await nautilusPostRequest(`/api/target_reports/get_ids/`, requestedIds))
        .data;
};

export function useGetIds() {
    const queryClient = useQueryClient();
    return useMutation((requestedIds: any) => getIdsRequest(requestedIds), {
        onSuccess: async (data) => {
            if (data) {
                Object.keys(data).forEach((key) => {
                    queryClient.setQueryData(idKeys.key(key), data[key]);
                });
            }
        },
    });
}
