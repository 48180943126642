import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form, withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { FormModal } from "components/molecules/Modal";
import { CONTENT_TYPES } from "constants/contentTypes";
import { useCreateIndicatorGroup } from "reactQuery/hooks/useIndicatorGroups";
import { useGetAllIndicators } from "reactQuery/hooks/useIndicators";
import { useGetAllInitiativeTypes } from "reactQuery/hooks/useInitiativeTypes";
import {
    editInsightSelector,
    insightBeingEditedSelector,
} from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const ERROR_MESSAGES = {
    indicatorIndustryMissing: "An indicator industry is required",
    indicatorCategoryMissing: "An indicator category is required",
    indicatorMissing: "An indicator is required",
};

const aNumber = (value) => typeof value === "number";

const addIndicatorSchema = Yup.object().shape({
    selectedIndicator: Yup.object().shape({
        industry_id: Yup.number().required(ERROR_MESSAGES.indicatorIndustryMissing),
        category_id: Yup.number().when("industry_id", {
            is: aNumber,
            then: Yup.number().required(ERROR_MESSAGES.indicatorCategoryMissing),
            otherwise: Yup.number().notRequired(),
        }),
        id: Yup.number().when("category_id", {
            is: aNumber,
            then: Yup.number().required(ERROR_MESSAGES.indicatorMissing),
            otherwise: Yup.number().notRequired(),
        }),
    }),
});

const addIndicatorStyles = makeStyles((theme) => ({
    form: {
        "&> div": {
            paddingBottom: theme.spacing(2),
            width: "100%",
        },
        "&> div:last-child": {
            paddingBottom: theme.spacing(0),
        },
    },
    formControl: {
        width: "100%",
    },
    segmentedControl: {
        paddingBottom: theme.spacing(4),
    },
}));

const AddIndicatorForm = ({ errors, setFieldValue, values }) => {
    const { selectedIndicator, selectedIndicatorTags, selectedInitiativeTypes } =
        values;

    const classes = addIndicatorStyles();

    const getAllInitiativeTypesQuery = useGetAllInitiativeTypes({});
    const getAllIndicatorsQuery = useGetAllIndicators();

    return (
        <Form className={classes.formControl}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            ...new Map(
                                (getAllIndicatorsQuery.data || []).map((indicator) => [
                                    indicator["industry_id"],
                                    indicator,
                                ])
                            ).values(),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.industry || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["industry_id"] === value["industry_id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                industry_id: value?.["industry_id"],
                                industry: value?.["industry"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-industry"
                                label="Indicator Industry"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.industry_id}
                                helperText={
                                    errors.selectedIndicator?.industry_id || null
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        disabled={!selectedIndicator?.["industry"]}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            selectedIndicator,
                            ...new Map(
                                (getAllIndicatorsQuery.data || [])
                                    .filter(
                                        (indicator) =>
                                            indicator["industry_id"] ===
                                            selectedIndicator?.["industry_id"]
                                    )
                                    .map((indicator) => [
                                        indicator["category_id"],
                                        indicator,
                                    ])
                            ).values(),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.category || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["category_id"] === value["category_id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                industry_id: selectedIndicator["industry_id"],
                                industry: selectedIndicator["industry"],
                                category_id: value?.["category_id"],
                                category: value?.["category"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-category"
                                label="Indicator Category"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.category_id}
                                helperText={
                                    errors.selectedIndicator?.category_id || null
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        disabled={!selectedIndicator?.["category"]}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            selectedIndicator,
                            ...(getAllIndicatorsQuery.data || []).filter(
                                (indicator) =>
                                    indicator["category_id"] ===
                                    selectedIndicator?.["category_id"]
                            ),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                ...selectedIndicator,
                                value: value?.["value"],
                                id: value?.["id"],
                                indicator_tags: value?.["indicator_tags"],
                                initiative_types: value?.["initiative_types"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator"
                                label="Indicator"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.id}
                                helperText={errors.selectedIndicator?.id || null}
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicatorTags}
                        disabled={!selectedIndicator?.["id"]}
                        multiple={true}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={selectedIndicator?.["indicator_tags"] || []}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicatorTags", value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-tags"
                                label="Indicator Tags"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedInitiativeTypes}
                        multiple={true}
                        loading={getAllInitiativeTypesQuery.isLoading}
                        options={getAllInitiativeTypesQuery.data || []}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedInitiativeTypes", value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-initiative-types"
                                label="Initiative Types"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

const UnconnectedAddIndicatorModal = ({
    insight,
    isShowingModal,
    isUpdatingInsight,
    toggleOverlay,
    ...formikProps
}) => {
    formikProps.values.createIndicatorGroupMutation = useCreateIndicatorGroup();

    if (!isShowingModal) {
        return null;
    }

    return (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.addIndicatorModal });
            }}
            handleSubmit={() => {
                formikProps.handleSubmit();
            }}
            isSubmitDisabled={isUpdatingInsight}
            isSubmitLoading={isUpdatingInsight}
            isVisible={isShowingModal}
            submitLabel={"Submit"}
            title={"Add Indicator"}
        >
            <AddIndicatorForm {...formikProps} />
        </FormModal>
    );
};

export const EnhancedAddIndicatorModal = withFormik({
    enableReinitialize: true,
    handleSubmit: async (
        {
            selectedIndicator,
            selectedIndicatorTags,
            selectedInitiativeTypes,
            createIndicatorGroupMutation,
        },
        {
            props: {
                insight: { id },
                toggleOverlay,
            },
        }
    ) => {
        const indicatorTagIds = selectedIndicatorTags.map((tag) => tag.id);
        const initiativeTypeIds = selectedInitiativeTypes.map(
            (initiativeType) => initiativeType.id
        );

        createIndicatorGroupMutation.mutate({
            content_type: CONTENT_TYPES.INSIGHT,
            indicator: selectedIndicator.id,
            indicator_tags: indicatorTagIds,
            initiative_types: initiativeTypeIds,
            object_id: id,
        });
        toggleOverlay({ overlay: overlayNames.addIndicatorModal });
    },
    mapPropsToValues: () => ({
        selectedIndicator: {},
        selectedIndicatorTags: [],
        selectedInitiativeTypes: [],
        createIndicatorGroupMutation: null,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: addIndicatorSchema,
})(UnconnectedAddIndicatorModal);

const mapStateToProps = (state) => {
    const editInsight = editInsightSelector(state);
    const isShowing = isShowingSelector(state);

    return {
        insight: insightBeingEditedSelector(state),
        isShowingModal: isShowing.addIndicatorModal,
        isUpdatingInsight: editInsight.isUpdating,
    };
};

const ConnectedAddIndicatorModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
})(EnhancedAddIndicatorModal);

export default ConnectedAddIndicatorModal;
