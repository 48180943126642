export const BUTTON_STATUS = {
    ERROR: "error",
    SUCCESS: "success",
};

export const FILTER_TYPES = {
    DEFAULT: "DEFAULT",
    DISABLED: "DISABLED",
    EXPLICIT_LOAD: "EXPLICIT_LOAD",
    MODAL: "MODAL",
    STAFF_EXPLICIT_LOAD: "STAFF_EXPLICIT_LOAD",
} as const;

export const FILTER_TEMPLATES = {
    IPL_RESULTS: "IPL_RESULTS",
} as const;

export const MODALS = {
    ASSIGN_IPL_RESULTS: "assigned_ipl_results",
    DATA_EXPORT: "data_export",
    FILTERS: "filters",
    SHARE_OPPORTUNITIES: "share_opportunities",
    SYNC_TO_CRM: "sync_to_crm",
    VIEW_DETAILS: "view_details",
};
