import { useQuery } from "@tanstack/react-query";

import { FEED_TYPES, LEGACY_FEED_TYPES } from "constants/feedTypes";
import { nautilusGetRequest } from "network";
import {
    tableDataExportKeys,
    tableDataKeys,
    tableFiltersKeys,
    tableItemKeys,
} from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

const API_PATH = {
    [FEED_TYPES.ALL_ASSIGNEES]: "all_users",
    [FEED_TYPES.ALL_FEEDBACK]: "all_feedback",
    [FEED_TYPES.ALL_INDICATORS]: "all_indicators",
    [FEED_TYPES.ALL_INSIGHTS]: "all_insights",
    [FEED_TYPES.ALL_OWNERS]: "all_owners",
    [FEED_TYPES.ALL_TEAM_INSIGHTS]: "all_team_insights",
    [FEED_TYPES.INDICATOR_FEED]: "indicator_feed",
    [FEED_TYPES.MY_INSIGHTS]: "my_insights",
    [FEED_TYPES.PRIORITY_INSIGHTS]: "priority_insights",
    [FEED_TYPES.LW_ALL_INSIGHTS]: "lw_all_insights",
    [FEED_TYPES.LW_ALL_RESULTS]: "lw_all_results",
    [FEED_TYPES.LW_MY_ACTIONED]: "lw_my_actioned",
    [FEED_TYPES.LW_MY_QUEUE]: "lw_my_queue",
    [FEED_TYPES.LW_READY_FOR_QC]: "lw_ready_for_qc",
    [FEED_TYPES.SINGLE_ASSIGNEE]: "single_user",
    [FEED_TYPES.SINGLE_FEEDBACK]: "single_feedback",
    [FEED_TYPES.SINGLE_INDICATOR]: "single_indicator",
    [FEED_TYPES.SINGLE_OWNER]: "single_owner",
    [FEED_TYPES.SINGLE_REPORT]: "single_report",
    [FEED_TYPES.TEAM_ACTIVITY]: "team_activity",
};

async function getTableData({
    feedType,
    pathParam,
    queryParams = "",
    secondaryPathParam,
    urlAction,
}: {
    feedType: string;
    pathParam?: string | number;
    queryParams?: string;
    secondaryPathParam?: string | number;
    urlAction?: string;
}) {
    const { data } = await nautilusGetRequest(
        `/api/target_reports/${
            LEGACY_FEED_TYPES.includes(feedType) ? "" : "paginated_table/"
        }${API_PATH[feedType]}/${pathParam ? `${String(pathParam)}/` : ""}${
            secondaryPathParam ? `${String(secondaryPathParam)}/` : ""
        }${urlAction ? `${urlAction}/` : ""}`,
        queryParams
    );
    return data;
}

export function useGetTableData({
    feedType,
    defaultPageSize,
    enabled = true,
    staleTime = 0,
}: {
    feedType: string;
    defaultPageSize?: number;
    enabled?: boolean;
    staleTime?: number;
}) {
    let queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
        defaultPageSize,
    });
    return useQuery({
        queryKey: tableDataKeys.createKey(feedType, queryParams),
        queryFn: () => getTableData({ feedType, queryParams }),
        enabled,
        staleTime,
        refetchOnWindowFocus: false,
    });
}

export function useGetTableFilters(
    feedType: string,
    id: number,
    secondaryId?: string | number,
    enabled: boolean = true
) {
    return useQuery({
        queryKey: tableFiltersKeys.createKey(feedType, id, secondaryId),
        queryFn: () =>
            getTableData({
                feedType,
                pathParam: id,
                secondaryPathParam: secondaryId,
                urlAction: "filters",
            }),
        enabled,
        refetchOnWindowFocus: false,
    });
}

export function useGetTableItemIds({
    feedType,
    defaultPageSize,
    enabled = true,
    requestedPage,
}: {
    feedType: string;
    defaultPageSize?: number;
    enabled?: boolean;
    requestedPage?: number;
}) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
        defaultPageSize,
        requestedPage,
    });
    return useQuery({
        queryKey: [tableItemKeys.createKey(feedType, queryParams)],
        queryFn: () =>
            getTableData({
                feedType,
                queryParams,
                urlAction: LEGACY_FEED_TYPES.includes(feedType)
                    ? "opportunities"
                    : "items",
            }),
        enabled: enabled,
    });
}

async function getRequestDataExport({
    feedType,
    pathParam,
    queryParams = "",
    secondaryPathParam,
    urlAction,
}: {
    feedType: string;
    pathParam?: string | number;
    queryParams?: string;
    secondaryPathParam?: string | number;
    urlAction?: string;
}) {
    const { data, headers, status } = await nautilusGetRequest(
        `/api/target_reports/${
            LEGACY_FEED_TYPES.includes(feedType) ? "" : "paginated_table/"
        }${API_PATH[feedType]}/${pathParam ? `${String(pathParam)}/` : ""}${
            secondaryPathParam ? `${String(secondaryPathParam)}/` : ""
        }${urlAction ? `${urlAction}/` : ""}`,
        queryParams
    );
    return { data, headers, status };
}

export function useRequestDataExport({
    feedType,
    defaultPageSize,
    enabled = true,
}: {
    feedType: string;
    defaultPageSize?: number;
    enabled?: boolean;
}) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
        defaultPageSize,
    });
    return useQuery({
        queryKey: tableDataExportKeys.createKey(feedType, queryParams),
        queryFn: () => getRequestDataExport({ feedType, queryParams }),
        enabled,
        refetchOnWindowFocus: false,
        select: (response) => {
            return {
                data: response.data,
                headers: response.headers,
                status: response.status,
            };
        },
    });
}
